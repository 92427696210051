import { AUTH_TYPES } from "../types/authTypes";

export const loginAction = (email, password) => {
  return {
    type: AUTH_TYPES.LOGIN_ACTION,
    email,
    password,
  };
};
export const createUser = (
  name,
  email,
  company,
  mobile,
  businessType,
  countryName,
  countryCode,
  gstNumber,
  portId,
  port
) => {
  return {
    type: AUTH_TYPES.CREATE_USER,
    name,
    email,
    company,
    mobile,
    businessType,
    countryName,
    countryCode,
    gstNumber,
    portId,
    port,
  };
};

export const forgotPassword = (emailId) => {
  return {
    type: AUTH_TYPES.FORGOT_PASSWORD,
    emailId,
  };
};

export const updatedPasswordAction = (password, token) => {
  return {
    type: AUTH_TYPES.UPDATE_PASSWORD,
    password,
    token,
  };
};
export const sendOtpAction = (mobile) => {
  return {
    type: AUTH_TYPES.SEND_OTP,
    mobile,
  };
};

export const verifyOTP = (code, mobile, email) => {
  return {
    type: AUTH_TYPES.VERIFY_OTP,
    code,
    mobile,
    email,
  };
};

export const getBusinessTypeOption = () => {
  return {
    type: AUTH_TYPES.GET_BUSINESS_TYPE_OPTION,
  };
};

export const updateAuthUser = (data) => {
  return {
    type: AUTH_TYPES.UPDATE_AUTH_USER,
    data,
  };
};

export const checkCurrentPassword = (data) => {
  return {
    type: AUTH_TYPES.CHECK_CURRENT_PASSWORD,
    data,
  };
};

export const resetPasswordToken = () => {
  return {
    type: AUTH_TYPES.RESET_PASSWORD_TOKEN,
  };
};
