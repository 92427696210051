import React from "react";
import { Route, Switch } from "react-router-dom";
import RODTEPForm from "./RODTEPForm";
import VerifyRODTEPForm from "./VerifyRODTEPForm";

const App = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={RODTEPForm} />
    <Route path={`${match.url}/verify-data`} component={VerifyRODTEPForm} />
  </Switch>
);

export default App;
