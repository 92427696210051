import React, { useState } from "react";
import { DownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import { axiosInstance } from "util/Api";
import { BASE_API_CONFIG } from "util/Api";
import ImageAdd from "assets/images/image-add.svg";
import { isValidString } from "../util/ApiHelper";
import { notification } from "antd";
import { size } from "lodash";
import { useGetBreakpoints } from "../hooks/useGetBreakpoints";

export default function Upload({
  id,
  label,
  src,
  onUpload,
  onRemove,
  index,
  isDisabled,
  originalName,
  allowedTypes = ["image/jpeg", "image/png", "application/pdf"],
}) {
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState(
    isValidString(originalName) ? originalName : ""
  );
  const validateFileType = (file) => {
    return allowedTypes.includes(file.type);
  };

  const validateFileSize = (file) => {
    return file.size <= 5 * 1024 * 1024; // 5 MB in bytes
  };
  const { isMobile } = useGetBreakpoints();
  const handleChange = async (e) => {
    if (e.target.files.length) {
      const fmData = new FormData();
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      fmData.append("image", e.target.files[0]);
      const file = e.target.files[0];

      if (file) {
        // Check file type and size
        if (validateFileType(file) && validateFileSize(file)) {
          setLoading(true);
          try {
            let response = await axiosInstance.post(
              `${BASE_API_CONFIG.baseURL}/file-manager/single`,
              fmData,
              config
            );
            setFileName(response?.data?.response?.originalname);
            const data = {
              index: index,
              id: id,
              src: `${response?.data?.response?.url}`,
              originalName: response?.data?.response?.originalname,
            };
            onUpload({ ...data });
          } catch (e) {
            console.log("e", e.message);
          } finally {
            setLoading(false); // Reset loading state to false after upload
          }
        } else {
          notification.error({
            description:
              "Invalid file.Please select a valid format under 5 MB.",
            duration: 5,
            placement: "topRight",
            style: isMobile ? { width: "80vw" } : {},
          });
        }
      }
    }
  };

  const downloadFile = (fileUrl, fileName) => {
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };
  const truncateFileName = (name, maxLength) => {
    if (name?.length <= maxLength) {
      return name;
    } else {
      const truncatedName = name?.slice(0, maxLength - 3) + "...";
      const extension = name?.split(".")?.pop();
      return truncatedName + "." + extension;
    }
  };
  const truncatedFileName = truncateFileName(fileName, 15);

  return (
    <div className="myexim-upload gx-mb-5">
      {!src ? (
        <div>
          <label for={id}>
            <div className="myexim-upload-box">
              <div>
                <img src={ImageAdd} alt="..." />
              </div>
              <div>{label}</div>
            </div>
          </label>
          {!isDisabled && (
            <input
              type="file"
              accept=".jpg, .jpeg, .png, .pdf"
              style={{ display: "none" }}
              id={id}
              onChange={handleChange}
            />
          )}
          {loading ? (
            <div style={{ textAlign: "center", padding: 5 }}>Uploading...</div>
          ) : null}
        </div>
      ) : (
        <>
          <div className="myexim-upload-preview">
            {(src?.includes("jpg") || src?.includes("png")) && (
              <img width="140" height="140" src={src} alt="..." />
            )}
            {src?.includes("pdf") && (
              <div>
                <div className="myexim-upload-doc-preview">PDF</div>
              </div>
            )}
            {src?.includes("pdf") && (
              <div className={"upload-filename-text"}>{truncatedFileName}</div>
            )}
          </div>
          <div className="myexim-upload-action">
            <>
              <DownloadOutlined onClick={() => downloadFile(src, src)} />
              {!isDisabled && (
                <DeleteOutlined onClick={() => onRemove({ id, index })} />
              )}
            </>
          </div>
        </>
      )}
    </div>
  );
}
