import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fetchStart, fetchSuccess } from "../../../appRedux/actions";
import { useDispatch } from "react-redux";
import { BASE_API_CONFIG, axiosInstance } from "../../../util/Api";
import "../banner/banner.less";
import { Button, Grid } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import offerPromo from "../../../assets/images/offerPromo.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import lineDesign from "../../../assets/images/line-design.svg";
import { useGetBreakpoints } from "../../../hooks/useGetBreakpoints";
import { PROMOTION_BANNER_STATUS } from "../../../util/Constant";

const PromotionBanner = () => {
  const dispatch = useDispatch();
  const [Promotions, setPromotions] = useState([]);
  const {isMobile} = useGetBreakpoints(); 

  useEffect(() => {
    fetchPromotions();
  }, [dispatch]);

  const fetchPromotions = async () => {
    try {
      dispatch(fetchStart());
      const response = await axiosInstance.get(
        `${BASE_API_CONFIG.baseURL}/promotions/offers?page=1&limit=100`
      );
      const approvedPromotions = response.data.data.rows.filter((promotion) => promotion.status ===PROMOTION_BANNER_STATUS.APPROVED);
      setPromotions(approvedPromotions);
    } catch (error) {
      console.error("Error fetching promotions:", error);
    } finally {
      dispatch(fetchSuccess());
    }
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="custom-arrow custom-prev-arrow" onClick={onClick}>
        <LeftOutlined style={{ color: "#FFFFFF" }} />
      </div>
    );
  };

  const NextArrow = ({ onClick }) => {
    return (
      <div className="custom-arrow custom-next-arrow" onClick={onClick}>
        <RightOutlined style={{ color: "#FFFFFF" }} />
      </div>
    );
  };
  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return Promotions && Promotions.length > 0 ? (
      <div>
      <Slider {...settings}>
        {Promotions.map((promotion) => (
          <div className="offer-promotion-banner">
            <div className="banner-image">
              <img src={offerPromo} alt="promo-img" />
              <img
                src={lineDesign}
                alt="watermark"
                className="watermark"
                style={{
                  opacity: 0.1,
                  position: "absolute",
                  top: "10%",
                  left: "10%",
                  transform: "translate(-50%, -50%)",
                  zIndex: -1,
                  width: "40%",
                  height: "300%",
                }}
              />
            </div>

            <div>
              <h2 className={isMobile ? "mobile-banner-title" : "banner-title"}>
                {promotion.title}
              </h2>
              <p className={isMobile ? "mobile-banner-desc" : "banner-desc"}>
                {promotion.description}
              </p>
              <Link to={`/banner-view?id=${promotion.id}`}>
                <Button className={isMobile?"mobile-avail-now":"avail-now"}>Avail this offer</Button>
              </Link>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  ) : null;
};

export default PromotionBanner;
