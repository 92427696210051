import { Form, Input, Row, Col, Select, Card, Button, InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { useHistory, useParams } from "react-router-dom";
import { Option } from "antd/es/mentions";
import { isValidElement } from "../../util/ApiHelper";
import { MY_EXIM_API_RFQ } from "../../appRedux/services/requestQuotationsNetwork";
import { showAlert } from "../../util/helper";
import { labelComponent } from "./../../util/UtilComponents";
import { fetchStart, fetchSuccess } from "../../appRedux/actions";
import { isExporter } from "./../../util/helper";
import { useGetBreakpoints } from "../../hooks/useGetBreakpoints";

const RODTEPForm = () => {
  let isEditDisabled = false;

  const { id } = useParams();
  const dispatch = useDispatch();
  const [form] = useForm();
  const history = useHistory();
  const { isMobile } = useGetBreakpoints();
  const [hsCodeId, setHsCodeId] = useState(null);
  const [filteredHsCodeData, setFilteredHsCodeData] = useState(null);
  const [hsCodeData, setHsCodeData] = useState(null);
  const authUser = useSelector(({ auth }) => auth.userData);
  const productList = useSelector((state) => state.myProducts.productsList);
  const categoryList = useSelector((state) => state.myProducts.categoryList);
  const hsCodeList = useSelector((state) => state.myProducts.hsCodeList || []);
  const countries = useSelector(({ common }) => common.countries);
  const ports = useSelector(({ common }) => common.ports);
  const [targetedBusinessType, setTargetedBusinessType] = useState(null);
  const [sourcePortsData, setSourcePortsData] = useState(null);
  const [selectedSourceCountry, setSelectedSourceCountry] = useState(
    isExporter(authUser) ? "India" : null
  );

  const handleHSCodeSearch = (value) => {
    const trimmedValue = value.trim();
    const containsNumbers = /^\d+$/.test(trimmedValue.toLowerCase());

    setFilteredHsCodeData(() => {
      const filterFunction = (option) => {
        const codeString = option.code
          .toString()
          .replace(/\s+/g, "")
          .toLowerCase();
        const descriptionString = option.description
          .replace(/[\n\r"]/g, "")
          .toLowerCase();

        return containsNumbers
          ? codeString.includes(trimmedValue.toLowerCase())
          : descriptionString.includes(trimmedValue.toLowerCase());
      };

      return isValidElement(hsCodeData)
        ? hsCodeData.filter(filterFunction)
        : hsCodeList?.filter(filterFunction);
    });
  };

  const handleChangeProductCategory = (value) => {
    form.resetFields(["hsCode"]);
    setHsCodeId(null);
    setFilteredHsCodeData(null);
    setHsCodeData(hsCodeList?.filter((item) => item?.categories?.id === value));
  };

  useEffect(() => {
    const countryId = countries.find(
      (item) => item.country === selectedSourceCountry
    )?.id;
    setSourcePortsData(ports.filter((item) => item.countryId === countryId));
  }, [countries, ports, selectedSourceCountry]);

  useEffect(() => {
    if (isValidElement(id)) {
      dispatch(fetchStart());
      MY_EXIM_API_RFQ.getRequestQuotationDetails({ id })
        .then((response) => {
          setTargetedBusinessType([
            ...response?.data?.data?.targetedBusinessType,
          ]);
          dispatch(fetchSuccess());
        })
        .catch((e) => {
          showAlert(e?.message);
          dispatch(fetchSuccess());
        });
    }
  }, [id]);

  const onFinish = (values) => {
    const data = {
      name: categoryList.find(
        (category) => category.id === values.productCategory
      )?.name,
      code: `${
        hsCodeData.find((hsCode) => hsCode.id === values.hscode)?.code
      } - ${
        hsCodeData.find((hsCode) => hsCode.id === values.hscode)?.description
      }`,
      port: values.sourcePort,
      totalRodtepValue: values.rodtepValue,
    };
    if (!isValidElement(values?.rodtepValue)) data["totalRodtepValue"] = "INR";
    history.push("/sell-rodtep/verify-data", { data });
    console.log("data", data);
  };

  return (
    <Card className="myexim-detail-card">
      <div className="myexim-table-title">
        {
          "Please submit the below details to provide the best buying Rate for your RODTEP."
        }
      </div>

      <Form form={form} onFinish={onFinish} className="gx-pt-4">
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="Product Category"
              name="productCategory"
              rules={[
                {
                  required: true,
                  message: "Product Category is required",
                },
              ]}
            >
              <Select
                showSearch
                size="large"
                name="productCategory"
                placeholder="Choose Product Category"
                dropdownStyle={{ maxHeight: "200px" }}
                onChange={handleChangeProductCategory}
                getPopupContainer={(trigger) => trigger.parentNode}
                listItemHeight={10}
                listHeight={250}
                filterOption={(input, option) => {
                  const children = option?.children ?? "";
                  const optionText = children.props?.children ?? "";
                  return optionText.toLowerCase().includes(input.toLowerCase());
                }}
              >
                {categoryList?.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    <div style={{ whiteSpace: "normal" }}>
                      {option.name.charAt(0).toUpperCase() +
                        option.name.slice(1).toLowerCase()}
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="HS code"
              name="hscode"
              rules={[
                {
                  required: true,
                  message: "HS code is required",
                },
              ]}
            >
              <Select
                showSearch
                size="large"
                name="hscode"
                placeholder="Choose HS code / Item Description"
                filterOption={false}
                disabled={isEditDisabled}
                getPopupContainer={(trigger) => trigger.parentNode}
                onSearch={handleHSCodeSearch}
              >
                {isValidElement(filteredHsCodeData)
                  ? filteredHsCodeData?.map((option) => (
                      <Option key={option.id} value={option.id}>
                        <div style={{ whiteSpace: "normal" }}>
                          {option.code} -{" "}
                          {option.description.replace(/[\n\r"]/g, "")}
                        </div>
                      </Option>
                    ))
                  : hsCodeData?.map((option) => (
                      <Option key={option.id} value={option.id}>
                        <div style={{ whiteSpace: "normal" }}>
                          {option.code} -{" "}
                          {option.description.replace(/[\n\r"]/g, "")}
                        </div>
                      </Option>
                    ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={12}>
            <Form.Item
              disabled={isEditDisabled}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label={labelComponent(
                "Loading Port",
                "Select the Loading Port where Goods to be loaded"
              )}
              name="sourcePort"
              rules={[
                {
                  required: true,
                  message: "Loading Port is required",
                },
              ]}
            >
              <Select
                showSearch
                size="large"
                placeholder="Select Loading Port"
                disabled={!selectedSourceCountry}
                getPopupContainer={(trigger) => trigger.parentNode}
                virtual={false}
                scrollToFirstError
              >
                {isValidElement(sourcePortsData)
                  ? sourcePortsData?.map((option) => (
                      <Option key={option.port} value={option.port}>
                        {`${option.port} (${option.code})`}
                      </Option>
                    ))
                  : ports?.map((option) => (
                      <Option key={option.port} value={option.port}>
                        {`${option.port} (${option.code})`}
                      </Option>
                    ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8} xs={24} sm={12} md={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              wrapperCol={{ span: 24 }}
              label="Total RODTEP Value"
              name="rodtepValue"
              rules={[
                {
                  required: true,
                  message: "Total RODTEP Value is required",
                },
              ]}
            >
              <InputNumber
                min={1}
                placeholder="RODTEP Value"
                style={{ width: "100%" }}
                autoComplete="off"
                type="number"
                addonBefore={
                "INR"
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="gx-mt-4">
          <Col span={24}>
            <Form.Item
              className="gx-text-center gx-mr-3"
              style={{
                justifyContent: isMobile ? "center" : "end",
                display: "flex",
                flex: 1,
              }}
            >
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{ width: 150 }}
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default RODTEPForm;
