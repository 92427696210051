import {Modal,Typography } from "antd";
import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

const LoadingPage = (isLoadingModalOpen,onCloseLoadingModal) => {
    const { Text } = Typography;

    const handleBack = () => {
      if (onCloseLoadingModal) onCloseLoadingModal();
    };

  return (
    <div>
      <Modal
        open={isLoadingModalOpen}
        onCancel={handleBack}
        bodyStyle={{
          height: "500px",
          borderRadius: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "40px",
        }}
        footer={null}
        closable={false}
        width="375px"
        maskClosable={false}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginBottom: "50px",
            position: "relative",
          }}
        >
          <Text
            style={{
              font: "Inter",
              fontSize: "24px",
              fontWeight: "bold",
              color: "#434E5B",
            }}
          >
            Loading...
          </Text>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginBottom: "40px",
            position: "relative",
          }}
        >
          <LoadingOutlined style={{fontSize:100,color:'#1890ff'}} />
        </div>

        <div
          style={{
            marginBottom: "30px",
          }}
        >
          <Text
            style={{
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            Please wait
          </Text>
        </div>
        
      </Modal>
      
    </div>
  );
};

export default LoadingPage
