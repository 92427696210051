import { Input, Card, List, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { isValidString } from "util/ApiHelper";
import { ExclamationCircleOutlined, LeftOutlined } from "@ant-design/icons";
import { axiosInstance, BASE_API_CONFIG } from "../../util/Api";
import { RFQStatus } from "../../util/Constant";
import { fetchStart, fetchSuccess } from "../../appRedux/actions";
import { isArrayNonEmpty } from "../../util/ApiHelper";

const { TextArea } = Input;

const ProductDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");

  const [userById, setUserById] = useState(null);

  const getuserById = async (id) => {
    if (isValidString(id)) {
      dispatch(fetchStart());
      try {
        const userById = await axiosInstance.get(
          `${BASE_API_CONFIG.baseURL}/users/${id}`
        );
        setUserById(userById?.data?.data);
      } catch (error) {
      } finally {
        dispatch(fetchSuccess());
      }
    }
  };

  useEffect(() => {
    if (isValidString(id)) {
      getuserById(id);
    }
  }, [dispatch, id]);

  const [fileName, setFileName] = useState("");
  const [showRejectModel, setShowRejectModel] = useState(false);
  const [reason, setReason] = useState("");

  useEffect(() => {
    if (isArrayNonEmpty(userById?.attachments)) {
      const fileNames = userById?.attachments?.map((image) => {
        return isValidString(image?.originalName)
          ? image?.originalName
          : image?.src;
      });
      setFileName(fileNames);
    }
  }, [userById]);

  const data = [
    {
      label: "Name",
      value: userById?.name,
    },
    {
      label: "Mobile",
      value: userById?.mobile,
    },
    {
      label: "Email",
      value: userById?.email,
    },
    {
      label: "Company Name",
      value: userById?.companyName,
    },
    {
      label: "City",
      value: userById?.city,
    },
    {
      label: "State",
      value: userById?.state,
    },
    {
      label: "Country",
      value: userById?.country,
    },
    {
      label: "Ratings",
      value: userById?.ratings,
    },
    {
      label: "Company Description",
      value: userById?.companyDescription,
    },
  ];

  const onApprovedClicked = async () => {
    history.goBack();
    const data = {
      id: id,
      status: RFQStatus.APPROVED,
    };
    await axiosInstance.put(
      `${BASE_API_CONFIG.baseURL}/users/${data?.id}`,
      data
    );
  };

  const onSubmitPressed = async () => {
    if (isValidString(reason)) {
      history.goBack();

      const data = {
        status: RFQStatus.REJECTED,
        remarks: reason,
      };
      await axiosInstance.put(`${BASE_API_CONFIG.baseURL}/users/${id}`, data);
    } else {
      alert("Please enter valid reason");
    }
  };

  const handleCancelModel = () => {
    setShowRejectModel(false);
    setReason("");
  };

  const onChangeClicked = (value) => {
    setReason(value);
  };

  return (
    <Card className="myexim-detail-card">
      <div className="myexim-table-title">
        <LeftOutlined
          className={"back-icon"}
          size="large"
          onClick={() => {
            history.goBack();
          }}
        />
        User Details
      </div>

      <List
        className="gx-mt-4"
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) =>
          item?.value && (
            <List.Item className="myexim-details-list">
              <List.Item.Meta
                title={<div>{item.label}</div>}
                description={item.value}
              />
            </List.Item>
          )
        }
      />
      {fileName?.length > 0 && (
        <div className="attachments">
          <div className="sub-header"> Attachments</div>
          <div className="gx-pt-4">
            {fileName?.map((fileName, index) => (
              <div key={index} className="gx-pb-3">
                <a
                  className="myexim-link"
                  href={`${BASE_API_CONFIG.baseURL}/${fileName}`}
                  download
                >
                  {fileName}
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
      <div
        style={{ justifyContent: "center", display: "flex", flex: 1 }}
        className="gx-pt-4"
      >
        <Button
          type="secondary"
          size="large"
          onClick={() => {
            setShowRejectModel(true);
          }}
        >
          REJECT
        </Button>
        <Button
          type="primary"
          size="large"
          onClick={() => {
            onApprovedClicked();
          }}
        >
          APPROVE
        </Button>
      </div>
      <Modal
        title="Confirmation"
        visible={showRejectModel}
        onCancel={handleCancelModel}
        centered // Center the modal vertically
        footer={null} // Hide the default footer
      >
        <div className={"delete-modal-content"}>
          <ExclamationCircleOutlined className={"delete-icon"} size={40} />{" "}
          {/* Delete warning icon */}
          <p>Do you want to reject this item?</p>
          <TextArea
            placeholder="Enter Reason"
            size={"large"}
            value={reason}
            onChange={(e) => onChangeClicked(e.target.value)}
          />
          <div style={{ marginTop: 30 }}>
            <Button type="default" onClick={() => handleCancelModel()}>
              Cancel
            </Button>
            <Button type="primary" onClick={() => onSubmitPressed()}>
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </Card>
  );
};

export default ProductDetails;
