import { isValidElement, NETWORK_METHOD } from "../../util/ApiHelper";
import { BASE_API_CONFIG } from "../../util/Api";
import { axiosInstance } from "util/Api";
import { put } from "redux-saga/effects";

export const MY_EXIM_API_RFQ = {
  getRequestQuotationList: (params) => ({
    method: NETWORK_METHOD.GET,
    url: `${BASE_API_CONFIG.baseURL}/enquiries?page=${params?.page}&limit=${params?.limit}`,
  }),
  deleteRequestQuotation: (param) => ({
    method: NETWORK_METHOD.DELETE,
    url: `${BASE_API_CONFIG.baseURL}/enquiries/${param?.RFQ_id}`,
  }),
  searchRequestQuotation: (param) => ({
    method: NETWORK_METHOD.GET,
    url: `${BASE_API_CONFIG.baseURL}/enquiries?filter=${param.searchText}`,
  }),
  getRequestQuotationDetails: (param) =>
    axiosInstance.get(`${BASE_API_CONFIG.baseURL}/enquiries/${param.id}`),
  getQuotationList: (param) =>
    axiosInstance.get(
      `${BASE_API_CONFIG.baseURL}/quotations?page=${param.page}&limit=${param.limit}&enquiryId=${param.enquiryId}`
    ),
  getQuotationDetails: (param) =>
    axiosInstance.get(`${BASE_API_CONFIG.baseURL}/quotations/${param.id}`),
  updateQuotationDetails: (param) =>
    axiosInstance.post(
      `${BASE_API_CONFIG.baseURL}/quotations/${param.id}`,
      isValidElement(param?.data) && param?.data
    ),
  searchQuotationsList: (param) =>
    axiosInstance.get(
      `${BASE_API_CONFIG.baseURL}/quotations?filter=${param.searchText}`
    ),
  getQuotationMessages: (param) => ({
    method: NETWORK_METHOD.GET,
    url: param?.isQuotation
      ? `${BASE_API_CONFIG.baseURL}/messages?quotationId=${param.id}`
      : `${BASE_API_CONFIG.baseURL}/messages?promotionId=${param.id}`,
  }),
};
