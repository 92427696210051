import { Button, Card } from "antd";
import React, {useState } from "react";
import PurchasePointModal from "./PurchasePointModal";
import SuccessPage from "./SuccessPage";
import FailedPage from "./FailedPage";
import LoadingPage from "./LoadingPage";

const MyBalance = ({currentBalance,refreshBalance}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isFailedModalOpen, setIsFailedModalOpen] = useState(false);
  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);
  
  const openPurchaseModal = () => {
    setIsModalOpen(true);
  };

  const closePurchaseModal = () => {
    setIsModalOpen(false);
  };

  const openSuccessModal = () => {
    setIsSuccessModalOpen(true);
  };

  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  const openFailedModal = () => {
    setIsFailedModalOpen(true);
  };

  const closeFailedModal = () => {
    setIsFailedModalOpen(false);
  };
  
  const openLoadingModal = () => {
    setIsLoadingModalOpen(true)
  }

  const closeLoadingModal = () => {
    setIsLoadingModalOpen(false);
  }

  return (
    <>
      <div>
        <Card
          style={{
            borderRadius: "5px",
            padding: "1px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "30px",
            }}
          >
            <div className="myexim-table-title">
              Current Balance: {currentBalance} Points
            </div>
            <Button
              type="primary"
              style={{
                color: "white",
                borderRadius: "5px",
                marginTop: "10px",
              }}
              onClick={openPurchaseModal}
            >
              Purchase Points
            </Button>
          </div>
        </Card>
      </div>
      {isModalOpen && (
        <PurchasePointModal
          isPurchasePointModalOpen={isModalOpen}
          onClose={closePurchaseModal}
          openSuccessModal={openSuccessModal}
          openFailedModal={openFailedModal}
          openLoadingModal={openLoadingModal}
          closeLoadingModal={closeLoadingModal}
          refreshBalance={refreshBalance}
        />
      )}

      {isSuccessModalOpen && (
        <SuccessPage
          isSuccessModalOpen={isSuccessModalOpen}
          onCloseSuccessModal={closeSuccessModal}
          currentBalance={currentBalance}
        />
      )}

      {isFailedModalOpen && (
        <FailedPage
          isFailedModalOpen={isFailedModalOpen}
          onCloseFailedModal={closeFailedModal}
          isPurchasePointModalOpen={isModalOpen}
        />
      )}

      {
        isLoadingModalOpen && (
          <LoadingPage
          isLoadingModalOpen={isLoadingModalOpen}
          onCloseLoadingModal={closeLoadingModal}
        />
        )
      }

    </>
  );
};

export default MyBalance;
