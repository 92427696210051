import React from "react";
import {Route, Switch} from "react-router-dom";
import MyUsersList from './MyUsersList';
import MyUsersDetails from "./MyUsersDetails";

const App = ({match}) => (
  <Switch>
    <Route exact path={`${match.url}`} component={MyUsersList}/>
    <Route path={`${match.url}/details/:id?`} component={MyUsersDetails}/>
  </Switch>
);

export default App;
