export const REQUEST_QUOTATIONS = {
  GET_REQUEST_QUOTATIONS_LIST: "get_quotations_list",
  REQUEST_QUOTATIONS_LIST_SUCCESS: "quotations_list_success",
  DELETE_QUOTATIONS_FROM_LIST: "delete_quotations_from_list",
  DELETE_QUOTATIONS_FROM_LIST_SUCCESS: "delete_quotations_from_list_success",
  GET_SEARCH_QUOTATIONS_FROM_LIST: "get_search_quotations_from_list",
  SEARCH_PRODUCTS_QUOTATIONS_FROM_LIST_SUCCESS:
    "search_quotations_from_list_success",
  GET_MESSAGES: "get_messages",
  GET_MESSAGES_SUCCESS: "get_messages_success",
};
