import React from "react";
import { Tag } from "antd";
import { EnquiryStatus } from "util/Constant";

const statusDetails = {
  [EnquiryStatus.QUOTATION_SENT]: {
    label: "Quotation Sent",
    color: "#F5993F",
  },
  [EnquiryStatus.ORDER_PLACED]: {
    label: "Awaiting For Order Confirmation",
    color: "#40507E",
  },
  [EnquiryStatus.ORDER_COFIRMED]: {
    label: "Order Confirmed",
    color: "#2FB07E",
  },
  [EnquiryStatus.NEW_ENQUIRY]: {
    label: "New Enquiry",
    color: "blue",
  },
  [EnquiryStatus.AWAITING_FOR_QUOTATION]: {
    label: "Awaiting For Quotation",
    color: "#9d9d9d",
  },
  [EnquiryStatus.PUBLISHED]: {
    label: "Published",
    color: "#008000",
  },
  [EnquiryStatus.REJECTED]: {
    label: "Rejected",
    color: "#FF0000",
  },
  [EnquiryStatus.APPROVED]: {
    label: "APPROVED",
    color: "#008000",
  },
  [EnquiryStatus.PENDING_APPROVAL]: {
    label: "PENDING APPROVAL",
    color: "#F5993F",
  },
  [EnquiryStatus.DRAFT]: {
    label: "DRAFT",
    color: "black",
  },
};

const EnquiryStatusTags = ({ status }) => {
  return (
    <div>
      <Tag color={statusDetails[status]?.color}>
        {statusDetails[status]?.label}
      </Tag>
    </div>
  );
};

export default EnquiryStatusTags;
