import { Card, Table } from "antd";
import React, { useEffect, useState } from "react";
import "./MyProfile.css";
import axios from "axios";
import { BASE_API_CONFIG } from "../../util/Api";
import { useSelector } from "react-redux";
import { formatDate } from "../../util/helper";

const MyActivity = ({pointsData}) => {
  
  const columns = [
    {
      title: "Date",
      key: "date",
      dataIndex: 'date',
      render: (text) => formatDate(text),
    },
    {
      title: "Description",
      key: "transactionname",
      dataIndex:'transactionname',
    },
    {
      title: "Points",
      key: "pointsconsumed",
      render: (text, record) => (
        <div>
          <img
            src={
              record.transactiontype === "CR"
                ? "/assets/images/positiveIcon.png"
                : "/assets/images/negativeIcon.png"
            }
            alt={
              record.pointsconsumed > 0
                ? "Positive points icon"
                : "Negative points icon"
            }
            style={{ marginRight: 8 }} // Optional: for spacing the icon from the number
          />
          <span
          style={{
            fontSize:"16px",
            fontWeight:"bold",
            fontFamily: "Inter", 
          }}>
          {record.transactiontype === "CR" ? "+" : "-"}
          {record.pointsconsumed} Points
          </span>
         
        </div>
      ),
    },
  ];

  return (
    <Card style={{ borderRadius: "5px" }}>
      <div className="myexim-table-myactivity-title">Points History</div>
      <Table
        rowKey={"key"}
        className="gx-table-responsive rfq-table myexim-table"
        columns={columns}
        dataSource={pointsData}
        pagination={{
          position: ["bottomRight"],
          pageSize: 5,
        }}
      />
    </Card>
  );
};

export default MyActivity;
