import React, { useState, useEffect, useCallback } from "react";
import { Tabs } from "antd";
import EnquiryDetails from "./EnquiryDetails";
import Quotation from "./Quotation";
import { isValidString } from "util/ApiHelper";
import { axiosInstance } from "util/Api";
import { BASE_API_CONFIG } from "util/Api";
import { useDispatch } from "react-redux";
import { fetchStart, fetchSuccess } from "appRedux/actions/Common";
import QuotationsSubmitDetails from "./QuotationSubmitedDetails";
import { isValidElement } from "../../util/ApiHelper";
import { useGetBreakpoints } from "../../hooks/useGetBreakpoints";
import { Card, Image, List, notification } from "antd";

export const tabKeys = {
  ENQUIRY_TAB: "ENQUIRY_TAB",
  QUOTATION_TAB: "QUOTATION_TAB",
};

const App = ({ match }) => {
  const { isMobile } = useGetBreakpoints();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");
  const qid = urlParams.get("qid");
  const [activeKey, setActiveKey] = useState(tabKeys.ENQUIRY_TAB);

  const [enquriesData, setEnquriesData] = useState(null);
  const [quotationData, setQuotationsData] = useState(null);
  const dispatch = useDispatch();

  const onNext = (key) => {
    setActiveKey(key);
  };

  const getEnquiryById = useCallback(async (id) => {
    if (isValidString(id)) {
      dispatch(fetchStart());
      try {
        const enquriesById = await axiosInstance.get(
          `${BASE_API_CONFIG.baseURL}/enquiries/${id}`
        );
        setEnquriesData(enquriesById?.data?.data);
      } catch (error) {
      } finally {
        dispatch(fetchSuccess());
      }
    }
  }, [dispatch]);

  const getQuotationById = useCallback(async (qid) => {
    if (isValidString(qid)) {
      const quotationResponse = await axiosInstance.get(
        `${BASE_API_CONFIG.baseURL}/quotations/${qid}`
      );
      setQuotationsData(quotationResponse);
    }
  }, []);

  useEffect(() => {
    getEnquiryById(id);
  }, [getEnquiryById, id]);

  useEffect(() => {
    getQuotationById(qid);
  }, [getQuotationById, qid]);

  const renderQuotation = useCallback(() => {
    console.log(quotationData);
    if (isValidElement(quotationData)) {
      return <QuotationsSubmitDetails quotationData={quotationData} />;
    } else {
      return (
        <Quotation
          quotationData={quotationData}
          enquriesData={enquriesData}
          onNext={onNext}
        />
      );
    }
  }, [enquriesData, quotationData]);

  const renderMobileContent = useCallback(() => {
    return activeKey === tabKeys.ENQUIRY_TAB ? (
      <EnquiryDetails enquriesData={enquriesData} onNext={onNext} />
    ) : (
      renderQuotation()
    )
  }, [activeKey, enquriesData, renderQuotation]);

  return isMobile ? (
    renderMobileContent()
  ) : (
    <div className="myexim-side-tabs">
      <Tabs
        tabPosition={"left"}
        defaultActiveKey={tabKeys.ENQUIRY_TAB}
        activeKey={activeKey}
        onChange={(key) => {
          console.log(key);
          setActiveKey(key);
        }}
        items={[
          {
            label: (
              <div>
                <div>
                  <span className="tab-title">Enquiry Details</span>
                </div>
                <div className="desc">Requirements and details</div>
              </div>
            ),
            key: tabKeys.ENQUIRY_TAB,
            children: (
              <EnquiryDetails enquriesData={enquriesData} onNext={onNext} />
            ),
          },
          {
            label: (
              <div>
                <div>
                  <span className="tab-title">Quotation Details</span>
                </div>
                <div className="desc">Information required for quotation</div>
              </div>
            ),
            key: tabKeys.QUOTATION_TAB,
            children: renderQuotation(),
          },
        ]}
      />
    </div>
  );
};

export default App;
