import React, { useEffect, useState } from "react";
import { Button, Card, Form, List, Modal, Tag, message } from "antd";
import { isValidElement, isValidString } from "../../util/ApiHelper";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LeftOutlined } from "@ant-design/icons";
import { fetchStart, fetchSuccess } from "../../appRedux/actions";
import { axiosInstance, BASE_API_CONFIG } from "../../util/Api";
import moment from "moment";
import Messages from "../messages";
import TextArea from "antd/lib/input/TextArea";

const PromotionView = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");
  const [promotionData, setPromotionData] = useState(null);
  const [isEdit, setIsEdit] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageSubmit, setMessageSubmit] = useState(false);

  const [form] = Form.useForm();

  const getPromotionById = async (id) => {
    if (isValidString(id)) {
      dispatch(fetchStart());
      try {
        const promotionById = await axiosInstance.get(
          `${BASE_API_CONFIG.baseURL}/promotions/${id}`
        );
        setPromotionData(promotionById?.data?.data);
        setIsEdit(promotionById?.data?.data?.status !== "approved");
      } catch (error) {
      } finally {
        dispatch(fetchSuccess());
      }
    }
  };
  useEffect(
    () => {
      getPromotionById(id);
      if (messageSubmit) {
        setMessageSubmit(false);
      }
    },
    [messageSubmit],
    [id]
  );

  const formattedStartDate = moment(promotionData?.startDate).format(
    "DD-MM-YYYY"
  );
  const formattedEndDate = moment(promotionData?.endDate).format("DD-MM-YYYY");
  const data = [
    {
      label: "Promotion Title",
      value: promotionData?.title,
    },
    {
      label: "Promotion Message",
      value: promotionData?.description,
    },
    {
      label: "Product Category",
      value: promotionData?.categories?.name?.replace(/\.$/,""),
    },
    {
      label: "Product Catogery / Item Description",
      value: promotionData?.hsCode
        ? `${promotionData?.hsCode?.name?.replace(/\.$/,"")} - ${promotionData?.hsCode?.description}`
        : "N/A",
    },
    {
      label: "Start Date",
      value: formattedStartDate,
    },
    {
      label: "End Date",
      value: formattedEndDate,
    },
    {
      label: "Destination Country",
      value: promotionData?.country?.country,
    },
    {
      label: "Destination Port",
      value: promotionData?.port?.port,
    },
    {
      label: "Buyer Message",
      value: promotionData?.buyerMessage,
    },
  ];
  const navigatePromotionEdit = () => {
    history.push(`add-edit?id=${id}`);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        setIsModalOpen(false);
        axiosInstance
          .post(`${BASE_API_CONFIG.baseURL}/messages`, {
            text: values.text,
            senderId: promotionData?.userId,
            sender: promotionData?.user?.name,
            receiverId: promotionData?.receiverId,
            receiver: promotionData?.receiver.name,
            enquiryId: promotionData?.enquiryId,
            quotationId: promotionData?.id,
          })
          .then((response) => {
            setMessageSubmit(true);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  return (
    isValidElement(promotionData) && (
      <>
        <Card className="myexim-detail-card" style={{ width: "100%" }}>
          <div className="myexim-table-title">
            <LeftOutlined
              className={"back-icon"}
              size="large"
              onClick={() => {
                history.goBack();
              }}
            />
            Promotion Details
          </div>
          <div className="logo-with-list gx-mt-4">
            <div style={{ flex: 1 }}>
              <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item) =>
                  item?.value && (
                    <List.Item className="myexim-details-list">
                      <List.Item.Meta
                        title={<div>{item.label}</div>}
                        description={
                          isValidString(item.value) ? item.value : "-"
                        }
                      />
                    </List.Item>
                  )
                }
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <div>
              <Button size="large" type="primary" onClick={showModal}>
                Send Message
              </Button>

              <Modal
                title="Negotiate"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <Form form={form} name="negotiateForm">
                  <Form.Item
                    name="text"
                    rules={[{ required: true, message: "Please enter text!" }]}
                  >
                    <TextArea rows={10} placeholder="Add your notes" />
                  </Form.Item>
                </Form>
              </Modal>
            </div>
            <div>
              {promotionData.status !== "approved" && (
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  onClick={navigatePromotionEdit}
                  style={{minWidth: "150px"}}
                >
                  Edit
                </Button>
              )}
            </div>
          </div>
        </Card>
        <Messages id={promotionData.id} isQuotation={false} />
      </>
    )
  );
};

export default PromotionView;
