import { all, takeLatest, put } from "redux-saga/effects";
import { AUTH_TYPES } from "../types/authTypes";
import { MY_EXIM_API } from "../services/authNetwork";
import { apiCall } from "util/Api";
import { isArrayNonEmpty, isValidElement } from "util/ApiHelper";
import { push } from "connected-react-router";
import { FETCH_START, FETCH_SUCCESS } from "./../types/commonTypes";
import { showAlert, isAdminApp } from "./../../util/helper";

function* makeLoginCall(action) {
  try {
    yield put({ type: FETCH_START });
    const response = yield apiCall(MY_EXIM_API.getLogin, action);
    if (isValidElement(response?.data?.data)) {
      localStorage.setItem("userData", JSON.stringify(response?.data?.data));
      localStorage.setItem(
        "token",
        JSON.stringify(response?.data?.data?.token)
      );
      yield put({
        type: AUTH_TYPES.LOGIN_ACTION_SUCCESS,
        payload: response?.data?.data,
      });
      if (isAdminApp()) {
        yield put(push(`/enquiries`));
      } else {
        yield put(push(`/`));
      }
    }
  } catch (e) {
    let error =
      e?.response?.data?.message === "Unauthorized"
        ? "Email/Password is Invalid"
        : e?.response?.data?.message;
    showAlert("error", error);
  } finally {
    yield put({ type: FETCH_SUCCESS });
  }
}

function* makeCreateUser(action) {
  try {
    yield put({ type: FETCH_START });
    const response = yield apiCall(MY_EXIM_API.createUser, action);
    if (isValidElement(response?.data)) {
      yield put({
        type: AUTH_TYPES.CREATE_USER_SUCCESS,
        payload: response?.data?.data,
      });
      yield* sendOTP(action);
    }
  } catch (e) {
    showAlert("error", e?.response?.data?.message);
  } finally {
    yield put({ type: FETCH_SUCCESS });
  }
}

function* makeForgotPasswordCall(action) {
  try {
    yield put({ type: FETCH_START });
    const response = yield apiCall(MY_EXIM_API.forgotPassword, action);
    if (isValidElement(response?.data)) {
      yield put(push(`checkemail?email=${action.emailId}`));
    }
  } catch (e) {
    showAlert("error", e?.response?.data?.message);
  } finally {
    yield put({ type: FETCH_SUCCESS });
  }
}

function* makeUpdatePasswordCall(action) {
  try {
    yield put({ type: FETCH_START });
    const response = yield apiCall(MY_EXIM_API.updatedPassword, action);
    yield put({ type: AUTH_TYPES.RESET_PASSWORD_TOKEN });
    if (response?.data?.message) {
      yield put(push(`passwordSuccess`));
    }
  } catch (e) {
    showAlert("error", e?.response?.data?.message);
  } finally {
    yield put({ type: FETCH_SUCCESS });
  }
}

function* sendOTP(action) {
  try {
    yield put({ type: FETCH_START });
    yield put({ type: AUTH_TYPES.SHOW_LOADER, payload: true });
    yield apiCall(MY_EXIM_API.sendOTP, action);
    yield put(push(`otpverify?mobile=${action.mobile}&email=${action.email}`));
  } catch (e) {
    showAlert("error", e?.response?.data?.message);
  } finally {
    yield put({ type: FETCH_SUCCESS });
  }
}

function* verifyOTP(action) {
  try {
    yield put({ type: FETCH_START });
    yield put({ type: AUTH_TYPES.SHOW_LOADER, payload: true });
    yield apiCall(MY_EXIM_API.verifyOTP, action);
    yield put(push(`checkemail?email=${action.email}`));
  } catch (e) {
    showAlert("error", e?.response?.data?.message);
  } finally {
    yield put({ type: AUTH_TYPES.SHOW_LOADER, payload: false });
    yield put({ type: FETCH_SUCCESS });
  }
}

function* makeBusinessTypeCall() {
  try {
    const response = yield apiCall(MY_EXIM_API.businessType);
    if (isArrayNonEmpty(response?.data?.data)) {
      yield put({
        type: AUTH_TYPES.PUT_BUSINESS_TYPE_OPTION_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: AUTH_TYPES.PUT_BUSINESS_TYPE_OPTION_SUCCESS,
        payload: null,
      });
    }
  } catch (e) {
    showAlert("error", e?.response?.data?.message);
  }
}

function* makeCheckCurrentPassword(action) {
  try {
    const response = yield apiCall(
      MY_EXIM_API.checkCurrentPassword,
      action?.data
    );
    if (response?.status === 200) {
      if (response?.data?.status) {
        yield put({
          type: AUTH_TYPES.CHECK_CURRENT_PASSWORD_SUCCESS,
          payload: response.data?.token,
        });
      } else {
        showAlert("error", "Password mismatch");
      }
    }
  } catch (e) {
    showAlert("error", e?.response?.data?.message);
  }
}

function* authSaga() {
  yield all([
    takeLatest(AUTH_TYPES.LOGIN_ACTION, makeLoginCall),
    takeLatest(AUTH_TYPES.CREATE_USER, makeCreateUser),
    takeLatest(AUTH_TYPES.FORGOT_PASSWORD, makeForgotPasswordCall),
    takeLatest(AUTH_TYPES.UPDATE_PASSWORD, makeUpdatePasswordCall),
    takeLatest(AUTH_TYPES.SEND_OTP, sendOTP),
    takeLatest(AUTH_TYPES.VERIFY_OTP, verifyOTP),
    takeLatest(AUTH_TYPES.GET_BUSINESS_TYPE_OPTION, makeBusinessTypeCall),
    takeLatest(AUTH_TYPES.CHECK_CURRENT_PASSWORD, makeCheckCurrentPassword),
  ]);
}

export default authSaga;
