import { REQUEST_QUOTATIONS } from "../types/RequestQuotationAction";

const INITIAL_STATE = {
  requestQuotationList: null,
  searchRequestQuotationsList: null,
  QuotationMessages: [],
};

const RequestQuotation = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_QUOTATIONS.REQUEST_QUOTATIONS_LIST_SUCCESS:
      return {
        ...state,
        requestQuotationList: action.payload,
      };
    case REQUEST_QUOTATIONS.SEARCH_PRODUCTS_QUOTATIONS_FROM_LIST_SUCCESS:
      return {
        ...state,
        searchRequestQuotationsList: action.payload,
      };
    case REQUEST_QUOTATIONS.GET_MESSAGES_SUCCESS:
      return {
        ...state,
        QuotationMessages: action.payload,
      };
    default:
      return state;
  }
};

export default RequestQuotation;
