import React, { useEffect, useState } from "react";
import { Card, Table, Dropdown, Input } from "antd";
import { MoreOutlined, EyeOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import EnquiryStatusTags from "./EnquiriesStatus";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { searchRFQDataAction } from "appRedux/actions/requestQuotationAction";
import { formatDate } from "util/helper";
import {
  isArrayNonEmpty,
  isValidElement,
  isValidString,
} from "../../util/ApiHelper";
import { axiosInstance, BASE_API_CONFIG } from "../../util/Api";
import { fetchSuccess, fetchStart } from "../../appRedux/actions";
import FilterButton from "../../components/FilterButton/FilterButton";
import { EnquiryStatus } from "../../util/Constant";

let navigateToDetails;

const columns = [
  {
    title: "Posted Date",
    key: "createdAt",
    render: (record) => <div>{formatDate(record?.createdAt)}</div>,
  },
  {
    title: "Company Name",
    key: "companyName",
    sorter: true,
    render: (record) => <div>{record?.user?.companyName}</div>,
  },
  {
    title: "Product",
    key: "productName",
    sorter: true,
    render: (record) => <div>{record?.productName}</div>,
  },
  {
    title: "Quantity",
    key: "quantityRequired",
    render: (record) => (
      <div>
        {record?.qty ? (
          <>
            {record?.qty} {record?.units}
          </>
        ) : (
          <>
            {record?.noOfContainers}{" "}
            {record?.noOfContainers == 1 ? "Container" : "Containers"}
          </>
        )}
      </div>
    ),
  },
  {
    title: "Status",
    key: "Status",
    sorter: true,
    render: (record) => {
      return <EnquiryStatusTags status={record?.status} />;
    },
  },
  {
    title: "Actions",
    key: "actions",
    render: (record) => (
      <div className="myexim-action-icon" onClick={(e) => e.stopPropagation()}>
        <Dropdown
          menu={{
            items: [
              {
                label: "View Details",
                key: "1",
                icon: <EyeOutlined />,
                onClick: () => {
                  navigateToDetails(record?.id);
                },
              },
            ],
          }}
          trigger={["click"]}
        >
          <MoreOutlined />
        </Dropdown>
      </div>
    ),
  },
];

const EnquiriesList = ({ fromDashboard }) => {
  const [searchText, setSearchText] = useState(null);
  const [adminEnquiriesList, setAdminEnquiriesList] = useState(null);
  const dispatch = useDispatch();

  const searchRFQList = useSelector(
    (state) => state.requestQuotations.searchRequestQuotationsList
  );
  const currentPage = 1;
  const dataSize = fromDashboard ? 5 : 10;

  const getEnquiriesListAPICCall = async (page, limit) => {
    const response = await axiosInstance.get(
      `${BASE_API_CONFIG.baseURL}/admin/enquiries?page=${page}&limit=${limit}&destinationCountry&sourceCountry&categoryId&status&filter&active`
    );
    setAdminEnquiriesList(response);
    dispatch(fetchSuccess());
  };

  useEffect(() => {
    dispatch(fetchStart());
    getEnquiriesListAPICCall(currentPage, dataSize);
  }, []);

  const onShowSizeChange = (current, pageSize) => {
    dispatch(fetchStart());
    getEnquiriesListAPICCall(current, pageSize);
  };
  const history = useHistory();

  navigateToDetails = (id) => {
    if (isValidElement(id)) {
      history.push(`enquiries/details?id=${id}`);
    }
  };

  const onSearch = (e) => {
    if (isValidString(e?.target?.value)) {
      dispatch(searchRFQDataAction(e.target.value));
      setSearchText(e.target.value);
    }
  };

  const tableData =
    isValidString(searchText) && searchText.length > 0
      ? isArrayNonEmpty(searchRFQList?.rows)
        ? searchRFQList
        : []
      : adminEnquiriesList?.data?.data;

  const [selectedStatus, setSelectedStatus] = useState(null);

  const handleSelectStatus = (value) => {
    setSelectedStatus(value);
  };
  const filteredTableData =
    tableData &&
    tableData.rows &&
    selectedStatus &&
    selectedStatus !== EnquiryStatus.ALL
      ? tableData.rows.filter((record) => record.status === selectedStatus)
      : tableData && tableData.rows
      ? tableData.rows
      : [];

  return (
    <Card title={<div className="myexim-table-title">Received Enquiries</div>}>
      {!fromDashboard && (
        <div className="myexim-table-header">
          {/* <div className="search-box">
            <Input
              placeholder="Search"
              size="large"
              onChange={onSearch}
              suffix={<SearchOutlined />}
              onPressEnter={(e) => onSearch(e.target.value)}
            />
          </div> */}
        </div>
      )}
      <FilterButton
        className={"rfq-filter-button"}
        availableStatus={Object.values(EnquiryStatus)}
        onSelectStatus={handleSelectStatus}
      />
      <Table
        rowKey={"id"}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        onRow={(record) => {
          return {
            onClick: (e) => {
              if (
                !e.target.classList.contains("ant-dropdown-menu-title-content")
              ) {
                navigateToDetails(record?.id);
              }
            },
          };
        }}
        className="gx-table-responsive rfq-table myexim-table"
        columns={columns}
        dataSource={filteredTableData}
        pagination={
          !fromDashboard && {
            position: ["bottomRight"],
            pageSize: 10,
            showSizeChanger: true,
            total: filteredTableData?.count,
            onChange: onShowSizeChange,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }
        }
      />
      {fromDashboard && (
        <div
          style={{ display: "flex", justifyContent: "end", marginBottom: 15 }}
        >
          <Link className="myexim-link" to="/enquiries">
            View More
          </Link>
        </div>
      )}
    </Card>
  );
};

export default EnquiriesList;
