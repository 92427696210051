import {useLocation,useHistory} from "react-router-dom";
import React from "react";
import {Button, Form} from "antd";
import {useDispatch,useSelector} from "react-redux";
import FormItem from "antd/es/form/FormItem";
import {useQuery} from "../util/hooks";
import {forgotPassword} from "../appRedux/actions";

const CheckEmail = () => {


  const dispatch = useDispatch();
  const history = useHistory();
  let query = useQuery();
  const email = query.get('email');
  const loading = useSelector(({common}) => common.loading);


  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mobile = searchParams.get('mobile');



  const onFinishFailed = errorInfo => {
  };

  const onFinish = values => {
    dispatch(forgotPassword(email));
  };

  const handleClick = () => {
    history.push('/signin')
  }

  return (

    <div className="gx-login-container">
      <div className="gx-login-content">
        <div className="gx-mb-4">
          <div className="gx-login-header">My Exim Business</div>
          <p className="gx-login-description">Password create link is sent to your email.</p>
          <p className="gx-login-description">Didn’t receive the email? Check spam or promotion folder.</p>
        </div>
        <Form
          initialValues={{remember: true}}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0">

          <FormItem className="gx-text-center">
            <Button type="primary" size="large" htmlType="submit" block loading={loading}>
              Resend Email
            </Button>
            <Button onClick={handleClick} type="secondary" size="large"  block>
              Back to Login
            </Button>
          </FormItem>



        </Form>


      </div>
    </div>

  );
}

export default CheckEmail;
