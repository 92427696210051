// src/data/promotionsData.js

export const Promotions = {
    PROMOTIONS_EXPORTER: [
    {
      id: 1,
      title: "Welcome To My Exim Business",
      description:
        "Currently we are in Pilot mode, If you face any issues / any feedback, Please write us to contactus@myeximbusiness.com",
    },
    {
      id: 2,
      title: "My Products Setup",
      description:
        "You must add your products under left side link 'My Products' to receive the Buyer Enquiries.",
    },
    {
      id: 3,
      title: "Buyer Enquiries",
      description:
        "Once you have added your products, You will receive Buyer Enquiries matching with the HS(4) of your product.",
    },
  ],
  PROMOTIONS_BUYER: [
    {
      id: 1,
      title: "Welcome To My Exim Business",
      description:
        "Currently we are in Pilot mode, If you face any issues / any feedback, Please write us to contactus@myeximbusiness.com",
    },
    {
      id: 2,
      title: "Request for Quotations(RFQ)",
      description:
        "You can raise your Enquiries using Left side link 'My RFQ', so that Indian Exporters receive your Enquiries and Reply with the Quotations.",
    },
    {
      id: 3,
      title: "View Exporters Profile",
      description:
        "Once Quotation is received from Indian Exporters, You can view Exporters Company Profile along with contact number and Email.",
    },
  ],
  PROMOTIONS_MANUFACTURER_EXPORTERS: [
    {
      id: 1,
      title: "Welcome To My Exim Business",
      description:
        "Currently we are in Pilot mode, If you face any issues / any feedback, Please write us to contactus@myeximbusiness.com",
    },
    {
      id: 2,
      title: "My Products Setup",
      description:
        "You must add your products under left side link 'My Products' to receive the Buyer Enquiries.",
    },
    {
      id: 3,
      title: "Buyer Enquiries",
      description:
        "Once you have added your products, You will receive Buyer Enquiries matching with the HS(4) of your product.",
    },
    {
      id: 4,
      title: "Exporters Enquiries",
      description:
        "Once you have added your products, You will receive Exporters Enquiries matching with the HS(4) of your product.",
    },
    {
      id: 5,
      title: "View Exporters Profile",
      description:
        "You can view Exporters Profile along with Contact Details under 'View Details' of Exporter Enquiry.",
    },
  ],
  PROMOTIONS_MANUFACTURER: [
    {
      id: 1,
      title: "Welcome To My Exim Business",
      description:
        "Currently we are in Pilot mode, If you face any issues / any feedback, Please write us to contactus@myeximbusiness.com",
    },
    {
      id: 2,
      title: "My Products Setup",
      description:
        "You must add your products under left side link 'My Products' to receive the  Enquiries from Exporters",
    },
    {
      id: 3,
      title: "Exporters Enquiries",
      description:
        "Once you have added your products, You will receive Exporters Enquiries matching with the HS(4) of your product.",
    },
    {
      id: 4,
      title: "View Exporters Profile",
      description:
        "You can view Exporters Profile along with Contact Details under 'View Details' of Exporter Enquiry.",
    },
  ],
  PROMOTIONS_COMMISSION_AGENTS: [
    {
      id: 1,
      title: "Welcome To My Exim Business",
      description:
        "Currently we are in Pilot mode, If you face any issues / any feedback, Please write us to contactus@myeximbusiness.com",
    },
    {
      id: 2,
      title: "My Products Setup",
      description:
        "You must add your products under left side link 'My Products' to receive the  Enquiries from Exporters",
    },
    {
      id: 3,
      title: "Exporters Enquiries",
      description:
        "Once you have added your products, You will receive Exporters Enquiries matching with the HS(4) of your product.",
    },
    {
      id: 4,
      title: "View Exporters Profile",
      description:
        "You can view Exporters Profile along with Contact Details under 'View Details' of Exporter Enquiry.",
    },
  ],
  PROMOTIONS_FREIGHT_FORWARDERS: [
    {
      id: 1,
      title: "Welcome To My Exim Business",
      description:
        "Currently we are in Pilot mode, If you face any issues / any feedback, Please write us to contactus@myeximbusiness.com",
    },
    {
      id: 2,
      title: "Exporter Enquiries",
      description:
        "You can access Exporters Freight Enquiries under left side Link 'Leads'.",
    },
  ],
  PROMOTIONS_SHIPPING_LINES: [
    { 
      id: 1,
      title: "Welcome To My Exim Business",
      description:
        "Currently we are in Pilot mode, If you face any issues / any feedback, Please write us to contactus@myeximbusiness.com",
    },
    {
      id: 2,
      title: "Exporter Enquiries",
      description:
        "You can access Exporters Freight Enquiries under left side Link 'Leads'.",
    },
  ],
  PROMOTIONS_CUSTOM_HOUSE_AGENT: [
    {
      id: 1,
      title: "Welcome To My Exim Business",
      description:
        "Currently we are in Pilot mode, If you face any issues / any feedback, Please write us to contactus@myeximbusiness.com",
    },
  ],
};
