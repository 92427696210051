import {NETWORK_METHOD} from "../../util/ApiHelper";
import {BASE_API_CONFIG} from "../../util/Api";


export const MY_EXIM_API_Products = {
  getProductList: (params) => ({
    method: NETWORK_METHOD.GET,
    url: `${BASE_API_CONFIG.baseURL}/products?page=${params?.page}&limit=${params?.limit}`,
  }),
  createProduct: (params) => ({
    method: NETWORK_METHOD.POST,
    url: `${BASE_API_CONFIG.baseURL}/products`,
    data: params.data
  }),
  updateProduct: (params) => ({
    method: NETWORK_METHOD.POST,
    url: `${BASE_API_CONFIG.baseURL}/products/${params?.id}`,
    data: params.data
  }),
  categoryList: () => ({
    method: NETWORK_METHOD.GET,
    url: `${BASE_API_CONFIG.baseURL}/categories?page=1&limit=100`
  }),
  hsCodeList: () => ({
    method: NETWORK_METHOD.GET,
    url: `${BASE_API_CONFIG.baseURL}/hscode`,
  }),
  deleteProduct:(param)=>({
    method: NETWORK_METHOD.DELETE,
    url:`${BASE_API_CONFIG.baseURL}/products/${param?.product_id}`
  }),
  searchProducts: (param)=>({
    method:NETWORK_METHOD.GET,
    url:`${BASE_API_CONFIG.baseURL}/products?filter=${param.searchText}`
  }),
  productById: (params)=>({
    method:NETWORK_METHOD.GET,
    url: `${BASE_API_CONFIG.baseURL}/products/${params?.id}`,
  }),
  productUnitList:()=>({
    method:NETWORK_METHOD.GET,
      url:`${BASE_API_CONFIG.baseURL}/static/units.json`
  })
}
