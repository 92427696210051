import { notification } from "antd";
import { APP_TYPE, RFQStatus, BUSINESS_TYPE } from "./Constant";
import moment from "moment";
import { isValidString } from "./ApiHelper";

export const showAlert = (type, title, desc) => {
  notification[type]({
    message: title,
    description: desc,
    placement: "top",
    duration: 2,
  });
};

export const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  const formattedDate = moment(date).format("DD-MMM-YYYY"); // Use 'en-GB' for 'DD/MM/YYYY' format
  return formattedDate;
};

export const isAdminApp = () => {
  return APP_TYPE.ADMIN === process.env.REACT_APP_APP_TYPE;
};

export const isUserApp = () => {
  return APP_TYPE.USER === process.env.REACT_APP_APP_TYPE;
};

export const logoFromString = (string) => {
  const parsedString = string?.slice(0, 2);
  return parsedString?.toUpperCase();
};

export const isLessThan24Hrs = (date) => {
  let hours = moment().diff(moment(date), "hours");
  return hours < 24;
};

export const isEnquiryPublished = (status) => {
  return (
    status &&
    status !== RFQStatus.DRAFT &&
    status !== RFQStatus.PENDING_APPROVAL
  );
};

export const getNewLine = (text) => {
  const newText =
    text &&
    text.split("\n").map((str) => (
      <>
        <div>{str}</div>
      </>
    ));
  return newText;
};

export const isExporter = (user) => {
  return user.businessTypes?.code === BUSINESS_TYPE.EXPORTER;
};

export const isCommissionAgent = (user) => {
  return user.businessTypes?.code === BUSINESS_TYPE.COMMISSION_AGENT;
};

export const isFreightForwarder = (user) => {
  return user.businessTypes?.code === BUSINESS_TYPE.FREIGHT_FORWARDER;
};

export const isShippingLine = (user) => {
  return user.businessTypes?.code === BUSINESS_TYPE.SHIPPING_LINE;
};

export const isBuyer = (user) => {
  return user.businessTypes?.code === BUSINESS_TYPE.BUYER;
};
export const isManufacturer = (user) => {
  return user.businessTypes?.code === BUSINESS_TYPE.MANUFACTURER;
};
export const isCHA = (user) => {
  return user.businessTypes?.code === BUSINESS_TYPE.CUSTOM_HOUSE_AGENT;
};
export const isShipping = (user) => {
  return user.businessTypes?.code === BUSINESS_TYPE.SHIPPING_LINE;
};
export const isManufacturerCumExporter = (user) => {
  return user.businessTypes?.code === BUSINESS_TYPE.MANUFACTURER_EXPORTER;
};

export const getTargetedBusinessTypeForEnquiry = (authUser) => {
  if (isBuyer(authUser)) {
    return BUSINESS_TYPE.EXPORTER;
  }
};

export const getEligibleUserForPointsAvailable = (authUser) => {
  const eligibleBusinesstypes = [
    BUSINESS_TYPE.EXPORTER,
    BUSINESS_TYPE.COMMISSION_AGENT,
    BUSINESS_TYPE.FREIGHT_FORWARDER,
    BUSINESS_TYPE.SHIPPING_LINE,
    BUSINESS_TYPE.MANUFACTURER,
    BUSINESS_TYPE.CUSTOM_HOUSE_AGENT,
    BUSINESS_TYPE.SHIPPING_LINE,
    BUSINESS_TYPE.MANUFACTURER_EXPORTER
  ];

  return eligibleBusinesstypes.includes(authUser?.businessTypes?.code);
}

export const getTargetedBusinessTypeForPromotion = (authUser) => {
  if (isExporter(authUser)) {
    return BUSINESS_TYPE.BUYER;
  } else if (
    isManufacturer(authUser) ||
    isFreightForwarder(authUser) ||
    isCHA(authUser)
  ) {
    return BUSINESS_TYPE.EXPORTER;
  }
};

export const isProductApplicable = (authUser) => {
  return [
    BUSINESS_TYPE.EXPORTER,
    BUSINESS_TYPE.COMMISSION_AGENT,
    BUSINESS_TYPE.MANUFACTURER,
    BUSINESS_TYPE.MANUFACTURER_EXPORTER,
  ].includes(authUser?.businessTypes?.code);
};

export const getBusinessType = (authUser) => {
  return authUser?.businessTypes?.code;
};

export const getInitials = (fullName) => {
  if (!fullName) return "";

  const names = fullName.split(" ");
  const initials = names.map((name) => name.charAt(0).toUpperCase()).join("");

  return initials;
};

export function capitalizeWordsByUnderscore(str) {
  if (isValidString(str)) {
    return str
      .split(" ") // Split the string by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(" "); // Join the words back together with spaces
  }
  return "";
}

export const formatCreatedAt = (createdAt) => {
  return moment(createdAt).format("h:mm A, D/M/YY");
};

export const addZohoSalesIQ = () => {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.id = "zsiqscript-loader";
  script.defer = true;
  script.innerHTML = `var $zoho = $zoho || {}; $zoho.salesiq = $zoho.salesiq || { mode: "async", widgetcode: "siq864ffea3b544edbe13e040f6d1016e3b5cb20fa9ffe642f69ead02ebfb371d15", values: {}, ready: function () { } }; var d = document; s = d.createElement("script"); s.type = "text/javascript"; s.id = "zsiqscript"; s.defer = true; s.src = "https://salesiq.zohopublic.in/widget?wc=siq864ffea3b544edbe13e040f6d1016e3b5cb20fa9ffe642f69ead02ebfb371d15"; t = d.getElementsByTagName("script")[0]; t.parentNode.insertBefore(s, t); // d.write("<div id='zsiqwidget'></div>");`;

  document.body.appendChild(script);
  setTimeout(() => {
    window.$zoho.salesiq.floatbutton.visible("show");
  }, 1000);
};

export const removeZohoSalesIQ = () => {
  if (
    !window.$zoho?.salesiq?.floatbutton ||
    !document.getElementById("zsiq_float")
  )
    return;
  window.$zoho.salesiq.floatbutton.visible("hide");
  document.getElementById("zsiq_float").classList.remove("zsiq-show");
};

export const lazyLoadScript = (src) => {
  return new Promise((resolve) => {
    const existingScript = document.getElementById(src);
    if (existingScript) {
      resolve(true);
      return;
    }
    const script = document.createElement("script");
    script.id = src;
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

