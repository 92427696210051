export const AUTH_TYPES = {
  LOGIN_ACTION: "login_action",
  LOGIN_ACTION_SUCCESS: "login_action_success",
  CREATE_USER: "create_user",
  CREATE_USER_SUCCESS: "create_user_success",
  FORGOT_PASSWORD: "forgot_password",
  UPDATE_PASSWORD: "updated_password",
  SEND_OTP: "send_otp",
  VERIFY_OTP: "verify_otp",
  SHOW_LOADER: "show_loader",
  GET_BUSINESS_TYPE_OPTION: "get_business_type_option",
  PUT_BUSINESS_TYPE_OPTION_SUCCESS: "put_business_type_option_success",
  LOGOUT_ACTION: "logout_action",
  UPDATE_AUTH_USER: "update_auth_user",
  UPDATE_PASSWORD: "update_password",
  CHECK_CURRENT_PASSWORD: "check_current_password",
  CHECK_CURRENT_PASSWORD_SUCCESS: "check_current_password_success",
  RESET_PASSWORD_TOKEN: "reset_password_token",
};
