
import React from 'react'
import { Button, Card, Col, Descriptions, Form, Row } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useGetBreakpoints } from '../../hooks/useGetBreakpoints';
import { BASE_API_CONFIG } from '../../util/Api';
import axios from 'axios';


const UnclaimedCargoVerify = () => {

  const location = useLocation();
  const history = useHistory();
  const { isMobile } = useGetBreakpoints();

  const { data } = location.state || { data: [] };
  console.log('data',data)
  
  const handleFormSubmit = async () => {
    try {
      const response = await axios({
        method: "post",
        url: `${BASE_API_CONFIG.baseURL}/unclaimed-cargo`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      });
      console.log("Response from backend:", response.data);
      history.goBack();
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  return (
      
        <Card
      className="myexim-detail-card"
      title={
        <div className="myexim-table-title gx-text-capitalize">
          <LeftOutlined
            className={"back-icon"}
            size="large"
            onClick={() => {
              history.goBack();
            }}
          />
           Please review Unclaimed Cargo-Details before Confirming
        </div>
      }
    >
        <Descriptions bordered column={1}>
        {Object.entries(data).map(([key, value]) => (
  <Descriptions.Item key={key} label={key}>
    {key === 'attachment' ? (
      Array.isArray(value) ? (
        value.map((imgSrc, idx) => (
          <div key={idx}>
            <img src={imgSrc} alt={`attachment-${idx}`} style={{ maxWidth: '100px' }} />
          </div>
        ))
      ) : (
        <div>
          <img src={value} alt="attachment" style={{ maxWidth: '100px' }} />
        </div>
      )
    ) : (
      value
    )}
  </Descriptions.Item>
))}
      </Descriptions>

      <Row className="gx-mt-4">
        <Col span={24}>
          <Form.Item
            className="gx-text-center gx-mr-3"
            style={{
              justifyContent: isMobile ? "center" : "end",
              display: "flex",
              flex: 1,
            }}
          >
            <Button
              type="secondary"
              size="large"
              style={{ width: 150 }}
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Button>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              style={{ width: 150 }}
              onClick={handleFormSubmit}
            >
              Confirm
            </Button>
          </Form.Item>
        </Col>
      </Row>

       

              
    
    </Card>
  )
}

export default UnclaimedCargoVerify