import { Button, Card, Col, Descriptions, Form, message, Row } from "antd";
import React from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { MY_EXIM_API_RFQ } from "../../appRedux/services/requestQuotationsNetwork";
import { useGetBreakpoints } from "../../hooks/useGetBreakpoints";
import axios from "axios";
import { BASE_API_CONFIG } from "../../util/Api";
import { CustomSuccessMessage } from "../../util/CustomMessage";

const VerifyRODTEPForm = () => {
  const location = useLocation();
  const history = useHistory();
  const { isMobile } = useGetBreakpoints();

  const { data } = location.state || { data: [] };

  console.log("location", location);

  const handleFormSubmit = async () => {
    console.log("Submitting data:", data);
    try {
      await axios({
        method: "post",
        url: `${BASE_API_CONFIG.baseURL}/rodtep`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      });
      message?.success({
        duration: 10,
        icon: <></>,
        content: (
          <CustomSuccessMessage
            title={"RODTEP"}
            message={`Your RODTEP is Submitted.`}
          />
        ),
        className: "custom-success-message",
      });
      history.goBack();
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const fieldLabels = {
    code: "Product Code",
    name: "Product Name",
    port: "Loading Port",
    totalRodtepValue: "Total RODTEP Value",
  };

  return (
    <Card title="Preview">
      <Descriptions bordered column={1} labelStyle={{ fontWeight: "bold",width:isMobile?"150px":"200px" }}>
        {Object.keys(fieldLabels).map((key, index) => (
          <Descriptions.Item key={index} label={fieldLabels[key]}>
            {data[key]}
          </Descriptions.Item>
        ))}
      </Descriptions>

      <Row className="gx-mt-4">
        <Col span={24}>
          <Form.Item
            className="gx-text-center gx-mr-3"
            style={{
              justifyContent: isMobile ? "center" : "end",
              display: "flex",
              flex: 1,
            }}
          >
            <Button
              type="secondary"
              size="large"
              style={{ width: 150 }}
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Button>
            <Button
              type="primary"
              size="large"
              onClick={handleFormSubmit}
              style={{ width: 150 }}
            >
              Confirm
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default VerifyRODTEPForm;
