import React from "react";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import FormItem from "antd/es/form/FormItem";
import { useHistory } from "react-router-dom";
import { forgotPassword } from "../appRedux/actions";
import SidebarLogo from "./Sidebar/SidebarLogo";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const loading = useSelector(({ common }) => common.loading);

  const history = useHistory();

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    dispatch(forgotPassword(values.email));
  };

  const handleClick = () => {
    history.push("/signin");
  };

  return (
    <>
      <SidebarLogo />
      <div className="gx-login-container">
        <div className="gx-login-content">
          <div className="gx-mb-4">
            <div className="gx-login-header">Forgot Password</div>
            <p className="gx-login-description">
              Enter the email you used to create your account so we can send you
              instructions on how to reset your password.
            </p>
          </div>
          <Form
            initialValues={{ remember: true }}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="gx-signin-form gx-form-row0"
          >
            <FormItem
              rules={[{ required: true, message: "Please input your E-mail!" }]}
              name="email"
            >
              <Input size="large" placeholder="Email" />
            </FormItem>

            <FormItem className="gx-text-center">
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                block
                loading={loading}
              >
                Send
              </Button>
              <Button onClick={handleClick} type="secondary" size="large" block>
                Back to Login
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
