import React from "react";
import {Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <Switch>
    <Route exact path={`${match.url}`} component={asyncComponent(() => import('./MyProfileView'))}/>
    <Route exact path={`${match.url}/edit`} component={asyncComponent(() => import('./MyProfileEdit'))}/>
  </Switch>
);

export default App;
