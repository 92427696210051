import React, { useCallback, useEffect, useState } from "react";
import { Avatar, Popover } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { isValidString } from "../../util/ApiHelper";
import { getEligibleUserForPointsAvailable, logoFromString } from "./../../util/helper";
import ProfileUpdatePasswordModal from "../../routes/myProfile/ProfileUpdatePasswordModal";
import { resetPasswordToken } from "../../appRedux/actions/authAction";
import { useDispatch } from "react-redux";
import { AUTH_TYPES } from "../../appRedux/types/authTypes";
import { useGetBreakpoints } from "../../hooks/useGetBreakpoints";
import axios from "axios";
import { BASE_API_CONFIG } from "../../util/Api";
import { ReloadOutlined } from "@ant-design/icons";

const UserInfo = () => {
  // const {userSignOut} = useAuth();
  const { isMobile } = useGetBreakpoints();
  const dispatch = useDispatch();
  const history = useHistory();
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const authUser = useSelector(({ auth }) => auth.userData);
  const [currentBalance, setCurrentBalance] = useState(0);
  const userData = useSelector(({ auth }) => auth?.userData);
  const [isSpinning, setIsSpinning] = useState(false);
  
  const onLogoutClick = () => {
    setPopoverVisible(false);
    dispatch({ type: AUTH_TYPES.LOGOUT_ACTION });
    localStorage.removeItem("token");
    history.replace("/signin");
  };

  const onMyProfileClick = () => {
    setPopoverVisible(false);
    history.push(`/my-profile`);
  };

  const onUpdatePasswordClick = () => {
    dispatch(resetPasswordToken());
    setPopoverVisible(false);
    setShowModal(true);
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={onMyProfileClick}>My Account</li>
      <li onClick={onUpdatePasswordClick}>Change Password</li>
      <li onClick={onLogoutClick}>Logout</li>
    </ul>
  );

  const renderCompanyLogo = useCallback(() => {
    return (
      <>
        {isValidString(authUser?.companyLogo) ? (
          <Avatar
            src={authUser?.companyLogo}
            className="gx-avatar gx-pointer"
            style={{ height: 40, width: 40 }}
          />
        ) : (
          <Avatar
            className="gx-avatar gx-pointer"
            style={{ height: 40, width: 40, backgroundColor: "#1890ff" }}
          >
            {logoFromString(authUser?.companyName ?? authUser?.name)}
          </Avatar>
        )}
      </>
    );
  }, []);

  const uuid = authUser.uuid;
  const getPoints = async () => {
    try {
      console.log("check URL", `${BASE_API_CONFIG.baseURL}/points/${uuid}`);
      const response = await axios.get(
        `${BASE_API_CONFIG.baseURL}/points/${uuid}`
      );
      setCurrentBalance(response.data?.data?.points || 0);
    } catch (error) {
      console.error("Error getting points:", error);
    } finally {
    }
  };

  const refreshGetPoints = () => {
    getPoints();
  };

  const handleRefreshButton = (e) =>{
    e.stopPropagation();
    setIsSpinning(true);
    refreshGetPoints();

    setTimeout(() => {
      setIsSpinning(false);
    },2000)
  }

  useEffect(() => {
    getPoints();
  }, [currentBalance]);

  return (
    <>
      <Popover
        overlayClassName="gx-popover-horizontal"
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
        visible={popoverVisible}
        onVisibleChange={(visible) => setPopoverVisible(visible)}
      >
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          {!isMobile ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                direction: "rtl",
              }}
            >
              <span
                className="gx-pl-2 gx-px-2"
                style={{
                  fontWeight: 600,
                  fontSize: 16,
                  margin: 6,
                }}
              >
                {isValidString(authUser?.companyName) && authUser?.companyName}
              </span>
              {getEligibleUserForPointsAvailable(userData) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    alignItems: "center",
                    paddingLeft: "20px",
                    marginRight: "12px",
                    gap: 4,
                  }}
                >
                  <img src={"/assets/images/points.png"} alt="point" />
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "#A1A7AD",
                    }}
                  >
                    Points Available: {currentBalance}
                  </span>
                  <ReloadOutlined
                    onClick={handleRefreshButton}
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      fontSize: "12px",
                      color: "#007BFF",
                      cursor: "pointer",
                      animation: isSpinning?`spin 0.3s linear`:"none",
                      right: 290,
                    }}
                  />
                </div>
              )}
            </div>
          ) : null}
          {renderCompanyLogo()}
          {!isMobile ? <i className="icon icon-chevron-down gx-pl-1" /> : null}
        </div>
      </Popover>
      {showModal ? (
        <ProfileUpdatePasswordModal
          onCancel={() => setShowModal(false)}
          showModal={showModal}
        />
      ) : null}
    </>
  );
};

export default React.memo(UserInfo);
