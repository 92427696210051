import {Modal,Typography } from "antd";
import React, { useEffect } from "react";

const FailedPage = ({isFailedModalOpen,onCloseFailedModal}) => {
  const { Text } = Typography;

    useEffect (() =>{
      if(isFailedModalOpen){
        const timer = setTimeout(() =>{
          if(onCloseFailedModal) onCloseFailedModal();
        },5000);
        return () => clearTimeout(timer);
      }
    })
  
    const handleBack = () => {
      if (onCloseFailedModal) onCloseFailedModal();
    };

  return (
    <div>
      <Modal
        open={isFailedModalOpen}
        onCancel={handleBack}
        marginTop="52px"
        bodyStyle={{
          height: "500px",
          borderRadius: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "40px",
        }}
        footer={null}
        closable={false}
        width="375px"
        maskClosable={false}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            margin: "10px",
            position: "relative",
          }}
        >
          <img
            onClick={handleBack}
            src="/assets/images/back.png"
            alt="back"
            style={{
              position: "absolute",
              left: "50px",
              width: "8px",
              height: "14px",
            }}
          />
          <Text
            style={{
              font: "Inter",
              fontSize: "14px",
              fontWeight: "bold",
              color: "#434E5B",
            }}
          >
            Purchase Points
          </Text>
        </div>

        <div
          style={{
            marginTop: "30px",
          }}
        >
          <Text
            style={{
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            Your transaction has failed.
          </Text>
        </div>

        <div
          style={{
            marginBottom: "30px",
          }}
        >
          <Text
            style={{
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            Please try again.
          </Text>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginBottom: "20px",
            position: "relative",
          }}
        >
          <img
            style={{ width: "135px", height: "135px" }}
            src="/assets/images/paymentFailed.png"
            alt="failed"
          />
        </div>
        <div
            style={{
              width: "80%",
              marginTop: "30px",
            }}
          >
            <button
              onClick={handleBack}
              style={{
                width: "100%",
                padding: "15px",
                backgroundColor: "#007bff",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                marginBottom: "20px",
                fontSize: "16px",
              }}
            >
              Try Again
            </button>
          </div>
      </Modal>
    </div>
  );
};
export default FailedPage;
