import {MY_PRODUCTS_TYPES} from "../types/myProductsTypes";

const INITIAL_STATE = {
  productsList: null,
  categoryList: null,
  hsCodeList: null,
  createSuccess: null,
  searchProducts:null,
  productsDetailsById: null,
  productsUnitList:null
};

const MyProducts =  (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MY_PRODUCTS_TYPES.GET_PRODUCTS_LIST_SUCCESS:
      return {
        ...state,
        productsList: action.payload
      };
    case MY_PRODUCTS_TYPES.GET_PRODUCT_BY_ID_SUCCESS:
      return {
        ...state,
        productsDetailsById: action.payload
      };
    case MY_PRODUCTS_TYPES.GET_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        categoryList: action.payload?.sort((a, b) => a.id - b.id)
      };
    case MY_PRODUCTS_TYPES.GET_HS_CODE_SUCCESS:
      return {
        ...state,
        hsCodeList: action.payload
      };
    case MY_PRODUCTS_TYPES.CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        createSuccess: action.payload
      };
    case MY_PRODUCTS_TYPES.SEARCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        searchProducts: action.payload
      }
    case MY_PRODUCTS_TYPES.PRODUCT_UNIT_LIST_SUCCESS:
      return {
        ...state,
        productsUnitList: action.payload
      }


    default:
      return state;
  }
};

export default MyProducts;
