import React, { useEffect, useState } from "react";
import { Card, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { searchProductsAction } from "appRedux/actions/myProductsAction";
import { useHistory } from "react-router-dom";
import { isArrayNonEmpty, isValidString } from "util/ApiHelper";
import { axiosInstance, BASE_API_CONFIG } from "../../util/Api";
import { fetchSuccess, fetchStart } from "../../appRedux/actions";

const formatDate = (createdAt) => {
  return new Date(createdAt).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

const MyWebsiteQueries = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [websiteQueries, setWebsiteQueries] = useState({
    count: 7,
    rows: [
      {
        id: 65,
        name: "Manimuthu",
        mobile: "+8012777876",
        address: "Chennai",
        message: "Good ",
        country: "India",
      },
    ],
  });

  const currentPage = 1;
  const dataSize = 10;

  const columns = [
    {
      title: "Name",
      key: "Name",
      dataIndex: "name",
      sorter: true,
      width: "25%",
      render: (text, record) => (
        <div className={"gx-flex-row gx-align-items-center"}>
          {/*<div className={"gx-pr-2"}>*/}
          {/*  <img*/}
          {/*    alt="..."*/}
          {/*    className={"gx-size-40 gx-rounded-base gx-border-2"}*/}
          {/*    src={*/}
          {/*      isValidString(record?.companyLogo)*/}
          {/*      ?  record?.companyLogo : "https://via.placeholder.com/150"*/}
          {/*    }*/}
          {/*  />*/}
          {/*</div>*/}
          <div className={"highlighted-column"}>{text}</div>
        </div>
      ),
    },
    {
      title: "Mobile Number",
      key: "Mobile Number",
      render: (record) => (
        <div>
          {record?.dialCode} {record?.mobile}
        </div>
      ),
    },
    {
      title: "Created At",
      key: "Created At",
      render: (record) => {
        return <div>{formatDate(record?.createdAt)}</div>;
      },
    },
    {
      title: "Updated At",
      key: "Updated At",
      render: (record) => {
        return <div>{formatDate(record?.updatedAt)}</div>;
      },
    },
    {
      title: "Country",
      key: "Country",
      render: (record) => <div>{record?.country}</div>,
    },
    {
      title: "Message",
      key: "Message",
      render: (record) => <div>{record?.message}</div>,
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   render: (record) => (
    //     <Dropdown
    //       menu={{
    //         items: [
    //           {
    //             label: "View",
    //             key: "1",
    //             icon: <EyeOutlined />,
    //             onClick: () => {
    //               navigateToDetails(record?.id);
    //             },
    //           },
    //         ],
    //       }}
    //     >
    //       <div
    //         className="myexim-action-icon"
    //         onClick={(e) => e.preventDefault()}
    //       >
    //         <Space>
    //           <MoreOutlined />
    //         </Space>
    //       </div>
    //     </Dropdown>
    //   ),
    // },
  ];

  const getWebsiteQueriesAPICCall = async (page, limit) => {
    const response = await axiosInstance.get(
      `${BASE_API_CONFIG.baseURL}/website-queries?&page=${page}&limit=${limit}`
    );
    setWebsiteQueries(response?.data?.data);
    dispatch(fetchSuccess());
  };

  useEffect(() => {
    dispatch(fetchStart());
    getWebsiteQueriesAPICCall(currentPage, dataSize);
  }, [dispatch]);
  const [searchText, setSearchText] = useState(null);

  const searchProductList = useSelector(
    (state) => state.myProducts.searchProducts
  );

  const onShowSizeChange = (current, pageSize) => {
    getWebsiteQueriesAPICCall(current, pageSize);
  };

  const onSearch = (e) => {
    if (isValidString(e?.target?.value)) {
      dispatch(searchProductsAction(e.target.value));
      setSearchText(e.target.value);
    }
  };
  // navigateToDetails = (id) => {
  //   if (isValidElement(id)) {
  //     dispatch(fetchStart());
  //     history.push(`users/details?id=${id}`);
  //   }
  // };

  const tableData =
    isValidString(searchText) && searchText.length > 0
      ? isArrayNonEmpty(searchProductList?.rows)
        ? searchProductList
        : []
      : websiteQueries;

  return (
    <Card
      title={
        <div style={{ display: "flex" }}>
          <div className="myexim-table-title">Website Queries</div>
        </div>
      }
    >
      <div>
        <Table
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          className="gx-table-responsive my-product-table myexim-table"
          total={tableData?.count}
          columns={columns}
          dataSource={tableData?.rows}
          pagination={{
            position: ["bottomRight"],
            showSizeChanger: true,
            pageSize: 10,
            total: tableData?.count,
            onChange: onShowSizeChange,
          }}
        />
      </div>
    </Card>
  );
};

export default MyWebsiteQueries;
