import { Form, Input, Row, Col, Select, Card, InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import { Badge, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import { BUSINESS_TYPE } from "../../util/Constant";
import {
  isBuyer,
  isManufacturer,
  isExporter,
  showAlert,
} from "../../util/helper";
import { isValidElement } from "react";
import { isValidString, safeStringValue } from "../../util/ApiHelper";
import { labelComponent } from "../../util/UtilComponents";
import { getLabel } from "../../util/ApiHelper";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Upload from "../../components/Upload";
import { startCase } from "lodash";
import { useDispatch } from "react-redux";
import {createProductAction} from "appRedux/actions/myProductsAction";


const UnclaimedCargo = () => {
  const history = useHistory();
  const [dataId, setDataId] = useState();
  const [form] = useForm();
  const { TextArea } = Input;
  const [targetedBusinessType, setTargetedBusinessType] = useState(null);
  const [hsCodeData, setHsCodeData] = useState(null);
  const [filteredHsCodeData, setFilteredHsCodeData] = useState(null);
  const [fileList, setFileList] = useState([{ src: "", label: "" }]);
  const authUser = useSelector(({ auth }) => auth.userData);
  const countries = useSelector(({ common }) => common.countries);
  const [selectedSourceCountry, setSelectedSourceCountry] = useState(
    isBuyer(authUser) || isExporter(authUser) ? "India" : null
  );
  const [sourcePortsData, setSourcePortsData] = useState(null);
  const [selectedDestinationCountry, setSelectedDestinationCountry] =
    useState(null);
  const [destinationPortsData, setDestinationPortsData] = useState(null);

  const ports = useSelector(({ common }) => common.ports);
  const productList = useSelector((state) => state.myProducts.productsList);
  const [hsCodeId, setHsCodeId] = useState(null);
  const dispatch = useDispatch();
  const categoryList = useSelector((state) => state.myProducts.categoryList);

  const [formValues, setFormValues] = useState({
    productCategory: '',
    hscode: '',
    sourceCountry: '',
    sourcePort: '',
    destinationCountry: '',
    destinationPort: '',
    description: '',
    currency: '',
    contractPrice: '',
    newPrice: '',
    availableRequired: '',
    uom: '',
    attachments: [], // Initialize as an empty array
  });

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]); // Get base64 data
      reader.onerror = (error) => reject(error);
    });
  };

  const handleUploadChange = async (info) => {
    if (info.fileList) {
      const updatedAttachments = [];
      for (const file of info.fileList) {
        if (file.originFileObj) {
          const base64 = await convertFileToBase64(file.originFileObj);
          updatedAttachments.push({ ...file, base64 });
        }
      }
      setFormValues((prevValues) => ({
        ...prevValues,
        attachments: updatedAttachments,
      }));
    }
  };

  const onFinish = (values) => {

    

    const attachments = values.attachments && Array.isArray(values.attachments)
    ? values.attachments.map((file) => file.base64)
    : []; 
    
    const data = 
      {
       
        productCategory: categoryList.find(
                               (category) => category.id === values.productCategory
                             )?.name,
   
        
         hscode:`${
                        hsCodeData.find((hsCode) => hsCode.id === values.hscode)?.code
                      } - ${
                        hsCodeData.find((hsCode) => hsCode.id === values.hscode)?.description
                      }`,
      
        sourceCountry:values.sourceCountry,
        sourcePort:values.sourcePort,
        destinationCountry:values.destinationCountry,
        destinationPort:values.destinationPort,
        description:values.description,
        currency:values.currency,
        contractPrice:values.contractPrice,
        newPrice:values.newPrice,
        availableRequired:values.availableRequired,
        uom:values.uom,
        attachment:fileList.filter((file) => file?.src).map((file) => file.src),
       
      }
      
    
    history.push("/unclaimed-cargo-verify", { data });
    console.log("data", data);
    
  }






  const uniqueProductRows = Array.from(
    new Map(
      (productList?.rows || [])
        .map((item) => [item?.categories?.id, item])
    ).values()
  );

  const handleChangeProductCategory = (value) => {
    form.resetFields(["hsCode"]);
    setHsCodeId(null);
    setFilteredHsCodeData(null);
    setHsCodeData(hsCodeList?.filter((item) => item?.categories?.id === value));
  };

  const location = useLocation();
  const path = location.pathname;
  const hasCloneParam = path.includes("clone");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");
    let isEditDisabled = false;
    let isEdit = !hasCloneParam && isValidElement(id);
  const productDetails = useSelector(
    (state) => state.myProducts.productsDetailsById
  );
  const handleChangeDestinationCountry = (value) => {
    setSelectedDestinationCountry(value);
    form.resetFields(["destinationPort"]);
  };

  const validatePortSelection = async (_, value) => {
    const sourcePort = form.getFieldValue("sourcePort");
    if (value === sourcePort) {
      throw new Error("Source Port and Destination Port cannot be the same");
    }
    return;
  };

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };

  useEffect(() => {
    if (isBuyer(authUser) || isExporter(authUser)) {
      form.setFieldValue("sourceCountry", "India");
      setTargetedBusinessType([
        ...[BUSINESS_TYPE.EXPORTER, BUSINESS_TYPE.MANUFACTURER_EXPORTER],
      ]);
    }
  }, [authUser]);

 const productsUnitList = useSelector(
    ({ myProducts }) => myProducts.productsUnitList || []
  );

  const uniqueProductsUnitList = Array.from(
    new Map(
      (productsUnitList || []).filter(Boolean).map((item) => [item.label, item])
    ).values()
  );



  const hsCodeList = useSelector((state) => state.myProducts.hsCodeList);


  const { Option } = Select;


  useEffect(() => {
    if (isValidElement(productDetails) && isValidString(id)) {
      form.setFieldsValue({
        productName: productDetails?.name,
        productCategory: productDetails?.categoryId,
        hscode: productDetails?.hsCodeId,
        productdescription: productDetails?.description,
        productSpecification: productDetails?.specification,

      });
      setDataId(productDetails?.id);
      if (productDetails?.images.length < 4) {
        const images = productDetails?.images;
        images.push({ src: "" });
        setFileList([...images]);
      } else {
        setFileList([...productDetails?.images]);
      }
    }
  }, [form, id, productDetails]);


  const handleChangeSourceCountry = (value) => {
    setSelectedSourceCountry(value);
    form.resetFields(["sourcePort"]);
  };




  const onUpload = (data) => {
    setFileList((prevFileList) => {
      const updatedList = [...prevFileList];
      updatedList[data.index].src = data.src;
      if (updatedList.length < 4) {
        updatedList.push({ src: "" });
      }
      updatedList[data.index].originalName = data.originalName;
      return [...updatedList];
    });
  };

  const onRemove = (data) => {
    setFileList((prevFileList) => {
      const updatedList = [...prevFileList];
      updatedList[data.index].src = "";
      return updatedList;
    });
  };

  const handleHSCodeSearch = (value) => {
    const trimmedValue = value.trim();
    const containsNumbers = /^\d+$/.test(trimmedValue.toLowerCase());

    setFilteredHsCodeData(() => {
      const filterFunction = (option) => {
        const codeString = option.code
          .toString()
          .replace(/\s+/g, "")
          .toLowerCase();
        const descriptionString = option.description
          .replace(/[\n\r"]/g, "")
          .toLowerCase();

        return containsNumbers
          ? codeString.includes(trimmedValue.toLowerCase())
          : descriptionString.includes(trimmedValue.toLowerCase());
      };

      return isValidElement(hsCodeData)
        ? hsCodeData.filter(filterFunction)
        : hsCodeList?.filter(filterFunction);
    });
  };

  return (

    <Card
      className="myexim-detail-card"
      title={
        <div className="myexim-table-title gx-text-capitalize">
          <LeftOutlined
            className={"back-icon"}
            size="large"
          // onClick={() => {
          //   history.goBack();
          // }}
          />
          {dataId ? "Edit" : "Add"} Unclaimed Cargo
        </div>
      }
    >
      <Form form={form} onFinish={onFinish}  >
        <Row gutter={16}>

          <Col xs={24} sm={12} md={12}>
           <Form.Item
                         labelCol={{ span: 24 }}
                         wrapperCol={{ span: 24 }}
                         label="Product Category"
                         name="productCategory"
                         rules={[
                           {
                             required: true,
                             message: "Product Category is required",
                           },
                         ]}
                       >
                         <Select
                           showSearch
                           size="large"
                           name="productCategory"
                           placeholder="Choose Product Category"
                           dropdownStyle={{ maxHeight: "200px" }}
                           onChange={handleChangeProductCategory}
                           getPopupContainer={(trigger) => trigger.parentNode}
                           listItemHeight={10}
                           listHeight={250}
                           filterOption={(input, option) => {
                             const children = option?.children ?? "";
                             const optionText = children.props?.children ?? "";
                             return optionText.toLowerCase().includes(input.toLowerCase());
                           }}
                         >
                           {categoryList?.map((option) => (
                             <Select.Option key={option.id} value={option.id}>
                               <div style={{ whiteSpace: "normal" }}>
                                 {option.name.charAt(0).toUpperCase() +
                                   option.name.slice(1).toLowerCase()}
                               </div>
                             </Select.Option>
                           ))}
                         </Select>
                       </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={12}>
           <Form.Item
                         labelCol={{ span: 24 }}
                         wrapperCol={{ span: 24 }}
                         label="HS code"
                         name="hscode"
                         rules={[
                           {
                             required: true,
                             message: "HS code is required",
                           },
                         ]}
                       >
                         <Select
                           showSearch
                           size="large"
                           name="hscode"
                           placeholder="Choose HS code / Item Description"
                           filterOption={false}
                           disabled={isEditDisabled}
                           getPopupContainer={(trigger) => trigger.parentNode}
                           onSearch={handleHSCodeSearch}
                         >
                           {isValidElement(filteredHsCodeData)
                             ? filteredHsCodeData?.map((option) => (
                                 <Option key={option.id} value={option.id}>
                                   <div style={{ whiteSpace: "normal" }}>
                                     {option.code} -{" "}
                                     {option.description.replace(/[\n\r"]/g, "")}
                                   </div>
                                 </Option>
                               ))
                             : hsCodeData?.map((option) => (
                                 <Option key={option.id} value={option.id}>
                                   <div style={{ whiteSpace: "normal" }}>
                                     {option.code} -{" "}
                                     {option.description.replace(/[\n\r"]/g, "")}
                                   </div>
                                 </Option>
                               ))}
                         </Select>
                       </Form.Item> 
          </Col>

          <Col xs={24} sm={12} md={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label={labelComponent(
                "Loading Country",
                "Currently we are allowing Loading country as India only"
              )}
              name="sourceCountry"
              rules={[
                {
                 
                  required: true,
                  message: "Loading Country is required",
                },
              ]}
            >
              <Select
                defaultValue={
                  isBuyer(authUser) || isExporter(authUser) ? "India" : null
                }
                disabled={
                  isEditDisabled ||
                  isBuyer(authUser) ||
                  isExporter(authUser)
                }
                showSearch
                size="large"
                getPopupContainer={(trigger) => trigger.parentNode}
                placeholder="Select Loading Country"
              >
                {countries?.map(
                  (option) =>
                    option.country !== "" && (
                      <Option key={option.country} value={option.country}>
                        {option.country}
                      </Option>
                    )
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              disabled={isEditDisabled}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label={labelComponent(
                "Loading Port",
                "Select the Loading Port where Goods to be loaded"
              )}
              name="sourcePort"
              rules={[
                {
                  required: true,
                  message: "Loading Port is required",
                },
              ]}
            >
              <Select
                showSearch
                size="large"
                placeholder="Select Loading Port"
                disabled={!selectedSourceCountry}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {isValidElement(sourcePortsData)
                  ? sourcePortsData?.map((option) => (
                    <Option key={option.port} value={option.port}>
                      {option.port}
                    </Option>
                  ))
                  : ports?.map((option) => (
                    <Option key={option.port} value={option.port}>
                      {option.port}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              disabled={isEditDisabled}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label={labelComponent(
                "Destination Country",
                "Select the Destination Country where Goods to be Delivered"
              )}
              name="destinationCountry"

              rules={[
                {
                  required: true,
                  message: "Destination Country is required",
                },
              ]}
            >
              <Select
                showSearch
                size="large"
                placeholder="Select Destination Country"
                onChange={handleChangeDestinationCountry}
                value={selectedDestinationCountry}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {countries?.map((option) => (
                  <Option key={option.country} value={option.country}>
                    {option.country}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              disabled={isEditDisabled}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label={labelComponent(
                "Destination Port",
                "Select the Destination Port where Goods to be Delivered"
              )}
              name="destinationPort"


              rules={[
                {
                  required: true,
                  message: "Destination Port is required",
                },
                { validator: validatePortSelection },
              ]}
            >
              <Select
                showSearch
                size="large"
                placeholder="Select Destination Port"
                disabled={!selectedDestinationCountry}
                getPopupContainer={(trigger) => trigger.parentNode}
                name="destinationPort"
                rules={[
                  {
                    required: true,
                    message: "Destination Port is required",
                  },
                ]}
              >
                {isValidElement(destinationPortsData)
                  ? destinationPortsData?.map((option) => (
                    <Option key={option.port} value={option.port}>
                      {option.port}
                    </Option>
                  ))
                  : ports?.map((option) => (
                    <Option key={option.port} value={option.port}>
                      {option.port}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="Description"
              name="description"

              rules={[
                {
                  required: true,
                  message: "Description is required",
                },
              ]}
            >
              <TextArea
                maxLength={288}
                showCount
                rows={5}
                placeholder={"Enter Product description "}
              />
            </Form.Item>
          </Col>

          <Col span={8} xs={24} sm={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label={labelComponent(
                "Currency",
                "Select the Quotation Currency"
              )}
              name="currency"

              rules={[
                {
                  required: true,
                  message: "Currency is required",
                },
              ]}
            >
              <Select
                showSearch
                size="large"
                placeholder="Select Currency"
                disabled={isManufacturer(authUser)}
              >
                {countries
                  ?.filter((country, index) => {
                    if (isManufacturer(authUser)) {
                      return country.currencyCode === "INR";
                    } else {
                      return (
                        index ===
                        countries.findIndex((o) => {
                          return (
                            country.currencyCode === o.currencyCode
                          );
                        })
                      );
                    }
                  })
                  .map(
                    (option) =>
                      option.currencyCode && (
                        <Option
                          key={option.id}
                          value={option.currencyCode}
                        >
                          {option.currencyCode}
                        </Option>
                      )
                  )}
              </Select>
            </Form.Item>
          </Col>

          <Row gutter={10}>
            <Col span={12}  >

              <Form.Item
                labelCol={{ span: 24 }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                wrapperCol={{ span: 24 }}
                label="Contract Price"
                name="contractPrice"

                rules={[
                  {
                    required: true,
                    message: "Contract Price is required",
                  },
                ]}
              >
                <Input
                  maxLength={48}
                  showCount
                  placeholder="Contract Price"
                  size={"large"}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>

            <Col span={12}  >
              <Form.Item
                labelCol={{ span: 24 }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                wrapperCol={{ span: 24 }}
                label="New Price"
                name="newPrice"

                rules={[
                  {
                    required: true,
                    message: "Contract Price is required",
                  },
                ]}
              >
                <Input
                  maxLength={48}
                  showCount
                  placeholder="New Price"
                  size={"large"}
                  autoComplete="off"
                />
              </Form.Item>

            </Col>
          </Row>
          <Col span={12} >
            <Form.Item
              disabled={isEditDisabled}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label={labelComponent("Available Quantity", null)}
              name="availableRequired"

              rules={[
                {
                  required: true,
                  message: "Available Quantity is required",
                },
              ]}
            >
              <Input
                disabled={isEditDisabled}
                placeholder="Available Quantity"
                size={"large"}
                type="number"
              />
            </Form.Item>
            </Col>
            <Col span={12}  >
            <Form.Item
                               disabled={isEditDisabled}
                               labelCol={{ span: 24 }}
                               wrapperCol={{ span: 24 }}
                               label={labelComponent("Unit of Measurement (UOM)", null)}
                               name="uom"
                               rules={[
                                 {
                                   required: true,
                                   message: "UOM is required",
                                 },
                               ]}
                             >
                               <Select
                                 showSearch
                                 size="large"
                                 placeholder="Select Unit of Measurement"
                                 disabled={isEditDisabled}
                                 getPopupContainer={(trigger) => trigger.parentNode}
                               >
                                 {uniqueProductsUnitList?.map((option) => (
                                   <Option key={option.label} value={option.label}>
                                     {option.label}
                                   </Option>
                                 ))}
                               </Select>
                             </Form.Item>

          </Col>

          <Row>
            <Col span={24}>
               <div className="sub-header" name="attachment"> Attachments</div>
                          <div
                      
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                            }}
                          >
                            {fileList.map((file, index) => {
                              return (
                                <Upload
                                  index={index}
                                  src={file.src}
                                  id={index}
                                  label={
                                    isValidString(file?.label) ? file?.label : getLabel(index)
                                  }
                                  originalName={file.originalName}
                                  onUpload={onUpload}
                                  onRemove={onRemove}
                                  isFrom={"MyProduct"}
                                />
                              );
                            })}
                          </div>
              <Col>
                <div className="upload-tips gx-mt-4">
                  <div className="header" style={{ display: "flex" }}>
                    <InfoCircleOutlined />
                    &nbsp; Tips{" "}
                  </div>
                  <div className="desc">
                    <Badge
                      status="default"
                      text="Attachment allowed files: jpg, png, gif, pdf"
                    />
                    <br />
                    <Badge
                      status="default"
                      text="Please attach the loading pictures, ownership documents to validate the Cargo details by our representative."
                    />
                    <br />
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </Row>


        <Row className="gx-mt-4">
          <Col span={24}>
            <Form.Item className="gx-text-center gx-mr-3" style={{ justifyContent: "end", display: "flex", flex: 1 }}>
              <Button
                type="secondary"
                size="large"
                style={{ width: 150 }}
              // onClick={() => {
              //   history.goBack();
              // }}
              >
                Cancel
              </Button>

              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{ width: 150 }}


              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>








      </Form>
    </Card>
  );
};

export default UnclaimedCargo;