import React from "react";
import { Route, Switch } from "react-router-dom";
import EnquiriesList from "./EnquiriesList";
import EnquiryDetails from "./EnquiryDetails";

const App = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={EnquiriesList} />
    <Route path={`${match.url}/details`} component={EnquiryDetails} />
  </Switch>
);

export default App;
