import React, { useEffect, useState } from "react";
import { Button, Input, Modal } from "antd";
import { isValidElement, isValidString } from "../../util/ApiHelper";
import { useDispatch, useSelector } from "react-redux";
import {
  checkCurrentPassword,
  updatedPasswordAction,
} from "../../appRedux/actions/authAction";

export default function ProfileUpdatePasswordModal({ showModal, onCancel }) {
  const dispatch = useDispatch();
  const resetPasswordToken = useSelector(
    (state) => state?.auth?.resetPasswordToken
  );
  const authUser = useSelector(({ auth }) => auth.userData);

  const [isCurrentPassword, setIsCurrentPassword] = useState(true);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassowrd, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    return () => {
      setConfirmPassword("");
      setCurrentPassword("");
      setNewPassword("");
      setErrors({});
    };
  }, []);

  const validate = () => {
    let errors = {};

    if (!isValidString(newPassword)) {
      errors.newPassword = "This field is required";
    }

    if (!isValidString(confirmPassowrd)) {
      errors.confirmPassowrd = "This field is required";
    }
    if (!newPassword.includes(confirmPassowrd)) {
      errors.confirmPassowrd = "Password does not match";
    }
    if (newPassword === currentPassword) {
      errors.newPassword = "New password cannot be same as current password";
    }
    setErrors({ ...errors });
    return Object.keys(errors).length > 0;
  };

  const onSubmitConfirmPassword = () => {
    if (validate()) {
      return;
    }
    dispatch(updatedPasswordAction(confirmPassowrd, resetPasswordToken));
  };

  const onSubmitCurrentPassword = () => {
    if (authUser?.id) {
      dispatch(
        checkCurrentPassword({
          id: authUser?.id,
          password: currentPassword,
        })
      );
    }
  };

  const renderCurrentPassword = () => {
    return (
      <div className={"delete-modal-content"}>
        <p style={{ textAlign: "left" }}>Please enter your current password</p>
        <Input.Password
          placeholder="Current password"
          value={currentPassword}
          onChange={(e) => {
            setCurrentPassword(e.target.value);
          }}
        />
        <div style={{ marginTop: 30 }}>
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" onClick={onSubmitCurrentPassword}>
            Submit
          </Button>
        </div>
      </div>
    );
  };

  const renderConfirmPassowrd = () => {
    return (
      <div className={"delete-modal-content"}>
        <Input.Password
          placeholder="Enter your new password"
          value={newPassword}
          onBlur={() => {
            if (newPassword === currentPassword) {
              errors.newPassword =
                "New password cannot be same as current password";
            }
            setErrors({ ...errors });
          }}
          onChange={(e) => {
            setNewPassword(e.target.value);
          }}
        />
        {errors?.newPassword && (
          <p style={{ textAlign: "left", color: "red", marginTop: 10 }}>
            {errors?.newPassword}
          </p>
        )}
        <Input.Password
          placeholder="Confirm your password"
          style={{ marginTop: 20 }}
          value={confirmPassowrd}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
        />
        {errors?.confirmPassowrd && (
          <p style={{ textAlign: "left", color: "red", marginTop: 10 }}>
            {errors?.confirmPassowrd}
          </p>
        )}
        <div style={{ marginTop: 30 }}>
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" onClick={onSubmitConfirmPassword}>
            Submit
          </Button>
        </div>
      </div>
    );
  };
  return (
    <Modal
      title="Change Password"
      visible={showModal}
      onCancel={onCancel}
      centered
      footer={null}
    >
      {isCurrentPassword && !isValidElement(resetPasswordToken)
        ? renderCurrentPassword()
        : renderConfirmPassowrd()}
    </Modal>
  );
}
