import React from "react";
import {Route, Switch} from "react-router-dom";
import MyProductsList from './MyProductsList';
import MyProductDetails from './MyProductDetails';
import MyProductView from "./MyProductView";

const App = ({match}) => (
  <Switch>
    <Route exact path={`${match.url}`} component={MyProductsList}/>
    <Route path={`${match.url}/details/:id?`} component={MyProductView}/>
    <Route path={`${match.url}/edit/:id?`} component={MyProductDetails}/>
  </Switch>
);

export default App;
