import {Modal,Typography } from "antd";
import React, { useEffect } from "react";


const SuccessPage = ({ isSuccessModalOpen, onCloseSuccessModal,currentBalance}) => {
  const { Text } = Typography;

  useEffect (() =>{
    if(isSuccessModalOpen){
      const timer = setTimeout(() =>{
        if(onCloseSuccessModal) onCloseSuccessModal();
      },5000);
      return () => clearTimeout(timer);
    }
  })

  const handleBack = () => {
    if (onCloseSuccessModal) onCloseSuccessModal();
  };

  return (
    <div>
      <Modal
        open={isSuccessModalOpen}
        onCancel={handleBack}
        bodyStyle={{
          height: "500px",
          borderRadius: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "40px",
        }}
        footer={null}
        closable={false}
        width="375px"
        maskClosable={false}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            margin: "10px",
            position: "relative",
          }}
        >
          <img
            onClick={handleBack}
            src="/assets/images/back.png"
            alt="back"
            style={{
              position: "absolute",
              left: "50px",
              width: "8px",
              height: "14px",
            }}
          />
          <Text
            style={{
              font: "Inter",
              fontSize: "14px",
              fontWeight: "bold",
              color: "#434E5B",
            }}
          >
            Purchase Points
          </Text>
        </div>

        <div 
              style={{
                margin:"30px"
              }}
              >
          <Text
            style={{
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            Your transaction has been successful
          </Text>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginBottom: "10px",
            position: "relative",
          }}
        >
          <img
            style={{ width: "135px", height: "135px" }}
            src="/assets/images/paymentSuccess.png"
            alt="success"
          />
        </div>
        <div 
              style={{
                marginTop:"30px"
              }}
              >
          <Text
            style={{
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            Current Balance: {currentBalance} Points
          </Text>
        </div>

        <div
          style={{
            width: "80%",
            marginTop: "20px",
          }}
        >
          <button
            onClick={handleBack}
            style={{
              width: "100%",
              padding: "15px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              marginBottom: "20px",
              fontSize: "16px",
            }}
          >
            Done
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default SuccessPage;
