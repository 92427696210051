import React from "react";
import {Route, Switch} from "react-router-dom";
import BusinessCard from "./BusinessCard"

const App = ({match}) => (
  <Switch>
    <Route exact path={`${match.url}`} component={BusinessCard}/>
  </Switch>
);

export default App;
