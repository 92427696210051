import React from "react";
import { Route, Switch } from "react-router-dom";
import enquiries from "./enquiries";
import products from "./products";
import users from "./users";
import websiteQueries from "./websiteQueries";
import myProfile from "./myProfile";
import promotions from "./promotions";
import registartions from "./registartions";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}enquiries`} component={enquiries} />
      <Route path={`${match.url}products`} component={products} />
      <Route path={`${match.url}users`} component={users} />
      <Route path={`${match.url}website-queries`} component={websiteQueries} />
      <Route path={`${match.url}my-profile`} component={myProfile} />
      <Route path={`${match.url}promotions`} component={promotions} />
      <Route path={`${match.url}registrations`} component={registartions} />
    </Switch>
  </div>
);

export default React.memo(App);
