import React from "react";
import { Typography, Card, Divider, Collapse } from "antd";
import SidebarLogo from "./Sidebar/SidebarLogo";

const TermsOfService = () => {
  const { Title, Paragraph } = Typography;

  // Inline style for scrollable container
  const scrollContainerStyle = {
    maxHeight: "500px", // Adjust the height as needed
    overflowY: "auto", // Enables vertical scrolling
    paddingRight: "10px", // Padding to avoid scrollbar overlap
  };

  return (
    <>
      <SidebarLogo />
      <Card style={{ margin: "20px", padding: "20px" }}>
        <div style={scrollContainerStyle}>
          <Typography>
            <Title level={2}>TERMS OF SERVICE AGREEMENT</Title>
            <Paragraph>
              Welcome to{" "}
              <a href="www.myeximbusiness.com" target="_blank">
                www.myeximbusiness.com
              </a>
              . This website is owned and operated by{" "}
              <b>
                <b>Thaswikha Exim Services Pvt Ltd</b>
              </b>{" "}
              & Plot no 221, Door no 8/8,Elango Street, Alwarthirunagar,
              Chennai,Chennai,Tamil Nadu,600087. By visiting our website and
              accessing the information, resources, services, products, and
              tools we provide, you understand and agree to accept and adhere to
              the following terms and conditions as stated in this policy
              (hereinafter referred to as the ‘Agreement’), along with the terms
              and conditions as stated in our Privacy Policy (please refer to
              the Privacy Policy section below for more information). We reserve
              the right to change this Agreement from time to time with/without
              notice. You acknowledge and agree that it is your responsibility
              to review this Agreement periodically to familiarize yourself with
              any modifications. Your continued use of this site after such
              modifications will constitute acknowledgment and agreement of the
              modified terms and conditions.
            </Paragraph>
            <Paragraph>
              PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY. BY USING
              THIS WEBSITE OR ORDERING PRODUCTS FROM THIS WEBSITE YOU AGREE TO
              BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.
            </Paragraph>
            <Paragraph>
              This Agreement governs your use of this website
              <a href="www.myeximbusiness.com" target="_blank">
                www.myeximbusiness.com
              </a>{" "}
              (hereinafter referred to as the ‘Website’),
              <b>
                <b>Thaswikha Exim Services Pvt Ltd</b>
              </b>
              c’s offer of Services for purchase on this Website, or your
              purchase of Services available on this Website. This Agreement
              includes and incorporates by this reference, the policies and
              guidelines referred below. Thaswikha Exim Services Pvt Ltd
              reserves the right to change or revise the terms and conditions of
              this Agreement at any time by posting any changes or a revised
              Agreement on this Website. Thaswikha Exim Services Pvt Ltd
              will/will not alert you that changes or revisions have been made
              by indicating on the top of this Agreement the date it was last
              revised. The changed or revised Agreement will be effective
              immediately after it is posted on this Website. Your use of the
              Website following the posting of any such changes or of a revised
              Agreement will constitute your acceptance of any such changes or
              revisions.{" "}
              <b>
                <b>Thaswikha Exim Services Pvt Ltd</b>
              </b>{" "}
              encourages you to review this Agreement whenever you visit the
              Website to make sure that you understand the terms and conditions
              governing use of the Website. This Agreement does not alter in any
              way the terms or conditions of any other written agreement you may
              have with{" "}
              <b>
                <b>Thaswikha Exim Services Pvt Ltd</b>
              </b>{" "}
              for other products or services. If you do not agree to this
              Agreement (including any referenced policies or guidelines),
              please immediately terminate your use of the Website.
            </Paragraph>
            <Title level={4}>Responsible Use And Conduct</Title>
            <Paragraph>
              In order to access our Resources/ Services, you may be required to
              provide certain information about yourself (such as
              identification, email, phone number, contact details, etc.) as
              part of the registration process, or as part of your ability to
              use the Resources. You agree that any information you provide will
              always be accurate, correct, and up to date. You are responsible
              for maintaining the confidentiality of any login information
              associated with any account you use to access our Resources/
              Services. Accordingly, you are responsible for all activities that
              occur under your accounts. Accessing (or attempting to access) any
              of our Resources/ Services by any means other than through the
              means we provide, is strictly prohibited. You specifically agree
              not to access (or attempt to access) any of our Resources/
              Services through any automated, unethical or unconventional means.
              Engaging in any activity that disrupts or interferes with our
              Resources/ Services, including the servers and/or networks to
              which our Resources / Services are located or connected, is
              strictly prohibited. Attempting to copy, duplicate, reproduce,
              sell, trade, or resell our Resources / Services is strictly
              prohibited. You are solely responsible for any consequences,
              losses, or damages that we may directly or indirectly incur or
              suffer due to any unauthorized activities conducted by you, as
              explained above, and may incur criminal or civil liability.
            </Paragraph>
            <Divider />
            <Title level={3}>Privacy:</Title>
            <Paragraph>
              This Website offers for sale certain Services (hereinafter
              referred to as ‘ Services’). By placing an order for Services
              through this Website, you agree to the terms set forth in this
              Agreement.
            </Paragraph>
            <Title level={4}>Customer Solicitation:</Title>
            <Paragraph>
              Unless you notify our third party call center representatives or
              direct{" "}
              <b>
                <b>Thaswikha Exim Services Pvt Ltd</b>
              </b>{" "}
              sales representatives, while they are calling you, of your desire
              to opt out from further direct company communications and
              solicitations, you are agreeing to continue to receive further
              emails and call solicitations from
              <b>
                <b>Thaswikha Exim Services Pvt Ltd</b>
              </b>{" "}
              and its designated in house or third party call team(s).
            </Paragraph>
            <Title level={4}>Opt Out Procedure:</Title>
            <Paragraph>
              We provide 3 easy ways to opt out of from future solicitations -
              You may use the opt out link found in any email solicitation that
              you may receive. You may also choose to opt out, via sending your
              email address to: [opt-out email]. You may send a written remove
              request to <b>Thaswikha Exim Services Pvt Ltd</b> sales
              representatives, while they are calling you, of your desire to opt
              out from further direct company communications and solicitations,
              you are agreeing to continue to receive further emails and call
              solicitations from
              <b>Thaswikha Exim Services Pvt Ltd</b>
              <b>
                & ADDRESS Plot no 221, Door no 8/8,Elango Street,
                Alwarthirunagar, Chennai,Chennai,Tamil Nadu,600087.{" "}
              </b>
            </Paragraph>
            <Title level={4}>Proprietary Rights:</Title>
            <Paragraph>
              <b>Thaswikha Exim Services Pvt Ltd</b> has proprietary rights and
              trade secrets in the Services. You may not copy, reproduce, resell
              or redistribute any Product manufactured and/or distributed by
              <b>Thaswikha Exim Services Pvt Ltd</b>.{" "}
              <b>Thaswikha Exim Services Pvt Ltd</b>
              also has rights to all trademarks and trade dress and specific
              layouts of this webpage, including calls to action, text
              placement, images and other information.
            </Paragraph>
            <Title level={4}>Taxes:</Title>
            <Paragraph>
              If you purchase any Services, you will be responsible for paying
              the applicable taxes. (GST)
            </Paragraph>
            <Title level={4}>
              Content, Intellectual Property, Third Party Links:
            </Title>
            <Paragraph>
              n addition to making Services available, this Website also offers
              information and marketing materials. This Website also offers
              information, both directly and through indirect links to
              third-party websites, about (kind of information). Thaswikha Exim
              Services Pvt Ltd does not always create the information offered on
              this Website; instead the information is often gathered from other
              sources. To the extent that <b>Thaswikha Exim Services Pvt Ltd</b>{" "}
              does create the content on this Website, such content is protected
              by intellectual property laws of the India, foreign nations, and
              international bodies. Unauthorized use of the material may violate
              copyright, trademark, and/or other laws. You acknowledge that your
              use of the content on this Website is for personal, non-commercial
              use. Any links to third-party websites are provided solely as a
              convenience to you. <b>Thaswikha Exim Services Pvt Ltd</b> does
              not endorse the contents on any such third-party websites.
              Thaswikha Exim Services Pvt Ltd is not responsible for the content
              of or any damage that may result from your access to or reliance
              on these third-party websites. If you link to third-party
              websites, you do so at your own risk.
            </Paragraph>
            <Title level={4}>Use of Website::</Title>
            <Paragraph>
              <b>Thaswikha Exim Services Pvt Ltd</b> is not responsible for any
              damages resulting from use of this website by anyone. You will not
              use the Website for illegal purposes. You will - abide by all
              applicable local, state, national, and international laws and
              regulations in your use of the Website (including laws regarding
              intellectual property), not interfere with or disrupt the use and
              enjoyment of the Website by other users, not resell material on
              the Website, not engage, directly or indirectly, in transmission
              of "spam", chain letters, junk mail or any other type of
              unsolicited communication, and not defame, harass, abuse, or
              disrupt other users of the Website.
            </Paragraph>
            <Title level={4}>License:</Title>
            <Paragraph>
              By using this Website, you are granted a limited, non-exclusive,
              non-transferable right to use the content and materials on the
              Website in connection with your normal, non-commercial use of the
              Website. You may not copy, reproduce, transmit, distribute, or
              create derivative works of such content or information without
              express written authorization from{" "}
              <b>Thaswikha Exim Services Pvt Ltd</b>
              or the applicable third party (if third party content is at
              issue).
            </Paragraph>
            <Title level={4}>Blogs:</Title>
            <Paragraph>
              We may provide various open communication tools on our website,
              such as blog comments, blog posts, public chat, forums, message
              boards, newsgroups, product ratings and reviews, various social
              media services, etc. You understand that generally we do not
              pre-screen or monitor the content posted by users of these various
              communication tools, which means that if you choose to use these
              tools to submit any type of content to our website, then it is
              your personal responsibility to use these tools in a responsible
              and ethical manner. By posting information or otherwise using any
              open communication tools as mentioned, you agree that you will not
              upload, post, share, or otherwise distribute any content that: is
              illegal, threatening, defamatory, abusive, harassing, degrading,
              intimidating, fraudulent, deceptive, invasive, racist, or contains
              any type of suggestive, inappropriate, or explicit language;
              infringes on any trademark, patent, trade secret, copyright, or
              other proprietary right of any party; contains any type of
              unauthorized or unsolicited advertising; impersonates any person
              or entity, including any{" "}
              <a href="www.myeximbusiness.com" target="_blank">
                www.myeximbusiness.com
              </a>
              /Thaswikha Exim Services Pvt Ltd employees or representatives.We
              have the right at our sole discretion to remove any content that,
              we feel in our judgment does not comply with this User Agreement,
              along with any content that we feel is otherwise offensive,
              harmful, objectionable, inaccurate, or violates any 3rd party
              copyrights or trademarks. We are not responsible for any delay or
              failure in removing such content. If you post content that we
              choose to remove, you hereby consent to such removal, and consent
              to waive any claim against us. We do not assume any liability for
              any content posted by you or any other 3rd party users of our
              website. However, any content posted by you using any open
              communication tools on our website, provided that it doesn't
              violate or infringe on any 3rd party copyrights or trademarks,
              becomes the property of
              <b>Thaswikha Exim Services Pvt Ltd</b>, and as such, gives us a
              perpetual, irrevocable, worldwide, royalty-free, exclusive license
              to reproduce, modify, adapt, translate, publish, publicly display
              and/or distribute as we see fit. This only refers and applies to
              content posted via open communication tools as described, and does
              not refer to information that is provided as part of the
              registration process, necessary in order to use our Resources. All
              information provided as part of our registration process is
              covered by our Privacy Policy. You agree to indemnify and hold
              harmless{" "}
              <a href="www.myeximbusiness.com" target="_blank">
                www.myeximbusiness.com
              </a>{" "}
              a digital property of Thaswikha Exim Services Pvt Ltd and its
              parent company and affiliates, and their directors, officers,
              managers, employees, donors, agents, and licensors, from and
              against all losses, expenses, damages and costs, including
              reasonable attorneys' fees, resulting from any violation of this
              User Agreement or the failure to fulfill any obligations relating
              to your account incurred by you or any other person using your
              account. We reserve the right to take over the exclusive defense
              of any claim for which we are entitled to indemnification under
              this User Agreement. In such event, you shall provide us with such
              cooperation as is reasonably requested by us.
            </Paragraph>
            <Title level={4}>Posting:</Title>
            <Paragraph>
              By posting, storing, or transmitting any content on the Website,
              you hereby grant <b>Thaswikha Exim Services Pvt Ltd</b> a
              perpetual, worldwide, non-exclusive, royalty-free, assignable,
              right and license to use, copy, display, perform, create
              derivative works from, distribute, have distributed, transmit and
              assign such content in any form, in all media now known or
              hereinafter created, anywhere in the world.{" "}
              <b>Thaswikha Exim Services Pvt Ltd</b>
              does not have the ability to control the nature of the user-
              generated content offered through the Website. You are solely
              responsible for your interactions with other users of the Website
              and any content you post. <b>
                Thaswikha Exim Services Pvt Ltd
              </b>{" "}
              is not liable for any damage or harm resulting from any posts by
              or interactions between users.{" "}
              <b>Thaswikha Exim Services Pvt Ltd</b>
              reserves the right, but has no obligation, to monitor interactions
              between and among users of the Website and to remove any content
              <b>Thaswikha Exim Services Pvt Ltd</b> deems objectionable, in
              Thaswikha Exim Services Pvt Ltd's sole discretion.
            </Paragraph>
            <Title level={4}>Disclaimer of Warranties:</Title>
            <Paragraph>
              Your use of this website and/or Services are at your sole risk.
              The website and Services are offered on an "as is" and "as
              available" basis. <b>Thaswikha Exim Services Pvt Ltd</b> expressly
              disclaims all warranties of any kind, whether express or implied,
              including, but not limited to, implied warranties of
              merchantability, fitness for a particular purpose and
              non-infringement with respect to the Services or website content,
              or any reliance upon or use of the website content or Services ("
              Services" include trial Services.) Without limiting the generality
              of the foregoing, <b>Thaswikha Exim Services Pvt Ltd</b> makes no
              warranty: that the information provided on this website is
              accurate, reliable, complete, or timely. that the links to
              third-party websites are to information that is accurate,
              reliable, complete, or timely. no advice or information, whether
              oral or written, obtained by you from this website will create any
              warranty not expressly stated herein as to the results that may be
              obtained from the use of the products or that defects in products
              will be corrected. regarding any Services purchased or obtained
              through the website. Some jurisdictions do not allow the exclusion
              of certain warranties, so some of the above exclusions may not
              apply to you.
            </Paragraph>
            <Title level={4}>Limitation Of Liability:</Title>
            <Paragraph>
              <b>Thaswikha Exim Services Pvt Ltd</b>’s entire liability, and
              your exclusive remedy, in law, in equity, or otherwise, with
              respect to the website content / Services and/or for any breach of
              this agreement is solely limited to the amount you paid, less
              shipping and handling charges, for Services purchased via this
              website.
              <b>Thaswikha Exim Services Pvt Ltd</b> will not be liable for any
              direct, indirect, incidental, special or consequential damages in
              connection with this agreement &/or Services in any manner,
              including liabilities resulting from: the use or the inability to
              use the website content / Services; the cost of procuring
              substitute content / Services; any information obtained / Services
              purchased or transactions entered into through the website; or any
              lost profits you allege. Some jurisdictions do not allow the
              limitation or exclusion of liability for incidental or
              consequential damages so some of the above limitations may not
              apply to you. conjunction with the Limitation of Liability as
              explained above, you expressly understand and agree that any claim
              against us shall be limited to the amount you paid, if any, for
              use of Services.{" "}
              <a href="www.myeximbusiness.com" target="_blank">
                www.myeximbusiness.com
              </a>
              /Thaswikha Exim Services Pvt Ltd will not be liable for any
              direct, indirect, incidental, consequential or exemplary loss or
              damages which may be incurred by you as a result of using our
              Resources / Services, or as a result of any changes, data loss or
              corruption, cancellation, loss of access, or downtime to the full
              extent that applicable limitation of liability laws apply.
            </Paragraph>
            <Title level={4}>Indemnification:</Title>
            <Paragraph>
              You will release, indemnify, defend and hold harmless Thaswikha
              Exim Services Pvt Ltd, and any of its contractors, agents,
              employees, officers, directors, shareholders, affiliates and
              assigns from all liabilities, claims, damages, costs and expenses,
              including reasonable attorneys' fees and expenses, of third
              parties relating to or arising out of this Agreement or the breach
              of your warranties, representations and obligations under this
              Agreement; the Website content or your use of the Website content;
              the Services or your use of the Services (including Trial
              Services); any intellectual property or other proprietary right of
              any person or entity; your violation of any provision of this
              Agreement; or any information or data you supplied to Thaswikha
              Exim Services Pvt Ltd. When <b>Thaswikha Exim Services Pvt Ltd</b>{" "}
              is threatened with suit or sued by a third party, Thaswikha Exim
              Services Pvt Ltd may seek written assurances from you concerning
              your promise to indemnify <b>Thaswikha Exim Services Pvt Ltd</b>;
              your failure to provide such assurances may be considered by
              Thaswikha Exim Services Pvt Ltd to be a material breach of this
              Agreement.
              <b>Thaswikha Exim Services Pvt Ltd</b> will have the right to
              participate in any defense by you of a third-party claim related
              to your use of any of the Website content / Services, with the
              counsel of
              <b>Thaswikha Exim Services Pvt Ltd</b>’s choice at its expense.
              Thaswikha Exim Services Pvt Ltd will reasonably cooperate in any
              defense by you of a third-party claim at your request and expense.
              You will have sole responsibility to defend{" "}
              <b>Thaswikha Exim Services Pvt Ltd</b>
              against any claim, but you must receive Thaswikha Exim Services
              Pvt Ltd’s prior written consent regarding any related settlement.
              The terms of this provision will survive any termination or
              cancellation of this Agreement or your use of the Website /
              Services.
            </Paragraph>
            <Title level={4}>Copyrights / Trademarks</Title>
            <Paragraph>
              All content and materials available on{" "}
              <a href="www.myeximbusiness.com" target="_blank">
                www.myeximbusiness.com
              </a>
              , including but not limited to text, graphics, website name, code,
              images and logos are the intellectual property of Thaswikha Exim
              Services Pvt Ltd and are protected by applicable copyright and
              trademark law. Any inappropriate use, including but not limited to
              the reproduction, distribution, display or transmission of any
              content on this site is strictly prohibited, unless specifically
              authorized by <b>Thaswikha Exim Services Pvt Ltd</b>.
            </Paragraph>
            <Title level={4}>Agreement To Be Bound</Title>
            <Paragraph>
              BY USING THIS WEBSITE OR ORDERING Services, YOU ACKNOWLEDGE THAT
              YOU HAVE READ AND AGREE TO BE BOUND BY THIS AGREEMENT AND ALL
              TERMS AND CONDITIONS ON THIS WEBSITE.
            </Paragraph>
            <Title level={4}>General Clause:</Title>
            <Paragraph>
              Force Majeure: <b>Thaswikha Exim Services Pvt Ltd</b> will not be
              deemed in default hereunder or held responsible for any cessation,
              interruption or delay in the performance of its obligations
              hereunder due to earthquake, flood, fire, storm, natural disaster,
              act of God, war, terrorism, armed conflict, labor strike, lockout,
              or boycott.
            </Paragraph>
            <Paragraph>
              Cessation of Operation: <b>Thaswikha Exim Services Pvt Ltd</b> may
              at any time, in its sole discretion and without advance notice to
              you, cease operation of the Website and distribution of the
              Services.
            </Paragraph>
            <Paragraph>
              Entire Agreement: This Agreement comprises the entire agreement
              between you and <b>Thaswikha Exim Services Pvt Ltd</b> and
              supersedes any prior agreements pertaining to the subject matter
              contained herein.
            </Paragraph>
            <Divider />
            <Title level={3}>Governing Law:</Title>
            <Paragraph>
              This website is controlled by{" "}
              <b>Thaswikha Exim Services Pvt Ltd</b> from our offices located in
              the state of Tamil Nadu, India. It can be accessed by most
              countries around the world. As each country has laws that may
              differ from those of Tamil Nadu, India, by accessing our website,
              you agree that the statutes and laws of Tamil Nadu, India, without
              regard to its conflict of law principles to the contrary and the
              United Nations Convention on the International Sales of Goods,
              will apply to all matters relating to the use of this website and
              the purchase of any Services through this site.
            </Paragraph>
            <Title level={4}>Effect of Waiver:</Title>
            <Paragraph>
              The failure of <b>Thaswikha Exim Services Pvt Ltd</b> to exercise
              or enforce any right or provision of this Agreement will not
              constitute a waiver of such right or provision. If any provision
              of this Agreement is found by a court of competent jurisdiction to
              be invalid, the parties nevertheless agree that the court should
              endeavor to give effect to the parties' intentions as reflected in
              the provision and the other provisions of this Agreement remain in
              full force and effect.
            </Paragraph>
            <Divider />
            <Title level={3}>Governing Law/Jurisdiction:</Title>
            <Paragraph>
              This Website originates from the Chennai, Tamil Nadu, India. This
              Agreement will be governed by the laws of the State of Tamil Nadu,
              India. It can be accessed by most countries around the world. As
              each country has laws that may differ from those of Tamil Nadu,
              India without regard to its conflict of law principles to the
              contrary. Neither you nor <b>Thaswikha Exim Services Pvt Ltd</b>{" "}
              will commenceTamil Nadu), India. This Agreement will be governed
              by the laws of the State of Tamil Nadu, India. It can be accessed
              by most countries around the world. As each country has laws that
              may differ from those of Tamil Nadu, India without regard to its
              conflict of law principles to the contrary. Neither you nor or
              prosecute any suit, proceeding or claim to enforce the provisions
              of this Agreement, to recover damages for breach of or default of
              this Agreement, or otherwise arising under or by reason of this
              Agreement, other than in courts located in State of Tamil Nadu,
              India. It can be accessed by most countries around the world. As
              each country has laws that may differ from those of Tamil Nadu,
              India. By using this Website or ordering Services, you consent to
              the jurisdiction and venue of such courts in connection with any
              action, suit, proceeding or claim arising under or by reason of
              this Agreement. You hereby waive any right to trial by jury
              arising out of this Agreement and any related documents. This
              website is controlled by <b>Thaswikha Exim Services Pvt Ltd</b>{" "}
              from our offices located in the state of Tamil Nadu, India. It can
              be accessed by most countries around the world. As each country
              has laws that may differ from those of Tamil Nadu, India, by
              accessing our website, you agree that the statutes and laws of
              Tamil Nadu, India without regard to the conflict of laws and the
              United Nations Convention on the International Sales of Goods,
              will apply to all matters relating to the use of this website and
              the purchase of any Services through this site. Furthermore, any
              action to enforce this User Agreement shall be brought in the
              courts having jurisdiction over such issue, located in Tamil Nadu,
              India. You hereby agree to judgement passed by such courts and
              waive any right to jurisdictional, venue, or inconvenient forum
              objections to such courts.
            </Paragraph>
            <Divider />
            <Title level={3}>Statute of Limitation:</Title>
            <Paragraph>
              You agree that regardless of any statute or law to the contrary,
              any claim or cause of action arising out of or related to use of
              the Website or Services or this Agreement must be filed within one
              (1) year after such claim or cause of action arose else be forever
              barred.
            </Paragraph>
            <Divider />
            <Title level={3}>Waiver of Class Action Rights:</Title>
            <Paragraph>
              BY ENTERING INTO THIS AGREEMENT, YOU HEREBY IRREVOCABLY WAIVE ANY
              RIGHT YOU MAY HAVE TO JOIN CLAIM WITH THOSE OF OTHERS IN THE FORM
              OF A CLASS ACTION OR SIMILAR PROCEDURAL DEVICE. ANY CLAIMS ARISING
              OUT OF OR RELATING TO OR IN CONNECTION WITH THIS AGREEMENT MUST BE
              ASSERTED INDIVIDUALLY.
            </Paragraph>
            <Divider />
            <Title level={3}>Termination:</Title>
            <Paragraph>
              <b>Thaswikha Exim Services Pvt Ltd</b> reserves the right to
              terminate your access to the Website if it reasonably believes, in
              its sole discretion, that you have breached any of the terms and
              conditions of this Agreement. Following termination, you will not
              be permitted to use the Website and{" "}
              <b>Thaswikha Exim Services Pvt Ltd</b>
              may, in its sole discretion and without advance notice to you,
              cancel any outstanding orders for Services. If your access to the
              Website is terminated, <b>Thaswikha Exim Services Pvt Ltd</b>{" "}
              reserves the right to exercise whatever means it deems necessary
              to prevent unauthorized access of the Website. This Agreement will
              survive indefinitely unless and until{" "}
              <b>Thaswikha Exim Services Pvt Ltd</b>
              chooses, in its sole discretion and without advance notice to you,
              to terminate it.
            </Paragraph>
            <Divider />
            <Title level={3}>Domestic Use:</Title>
            <Paragraph>
              Cancellation of order is not possible once the payment has been
              made. No refunds will be given except in the event of cancellation
              or non-performance of service by{" "}
              <b>Thaswikha Exim Services Pvt Ltd</b>.{" "}
            </Paragraph>
            <Paragraph>
              <b>Thaswikha Exim Services Pvt Ltd</b> makes no representation
              that the Website or Services are appropriate or available for use
              in locations outside India. Users who access the Website from
              outside India do so at their own risk and initiative and must bear
              all responsibility for compliance with any applicable local laws.
            </Paragraph>

            <Title level={3}>Guarantee:</Title>
            <Paragraph>
              Unless otherwise expressed, <b>Thaswikha Exim Services Pvt Ltd</b>{" "}
              &{" "}
              <a href="www.myeximbusiness.com" target="_blank">
                www.myeximbusiness.com
              </a>{" "}
              expressly disclaims all warranties and conditions of any kind,
              whether express or implied, including, but not limited to the
              implied warranties and conditions of merchantability, fitness of
              content / Services for a particular purpose and non-infringement.
            </Paragraph>
            <Title level={3}>Assignment:</Title>
            <Paragraph>
              You may not assign your rights and obligations under this
              Agreement to anyone. <b>Thaswikha Exim Services Pvt Ltd</b> may
              assign its rights and obligations under this Agreement in its sole
              discretion and without advance notice to you. BY USING THIS
              WEBSITE OR ORDERING Services FROM THIS WEBSITE YOU AGREE TO BE
              BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.{" "}
            </Paragraph>
            <Title level={3}>Contact Information:</Title>
            <Paragraph>
              If you have any questions or comments about these our Terms of
              Service as outlined above, you can contact us at:{" "}
              <b>
                <b>Thaswikha Exim Services Pvt Ltd</b> Plot no 221, Door no
                8/8,Elango Street, Alwarthirunagar, Chennai,Chennai,Tamil
                Nadu,600087 contactus@myeximbusiness.com{" "}
              </b>
            </Paragraph>
          </Typography>
        </div>
      </Card>
    </>
  );
};

export default TermsOfService;
