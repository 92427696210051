import { Form, Input, Row, Col, Select, message, Card } from "antd";
import React, { useEffect, useState } from "react";
import { Button, DatePicker } from "antd";
import {
  getCategoryListAction,
  getHSCodeAction,
} from "appRedux/actions/myProductsAction";
import { useDispatch, useSelector } from "react-redux";
import { axiosInstance, BASE_API_CONFIG } from "../../util/Api";
import { useHistory } from "react-router-dom";
import { isValidElement, isValidString } from "util/ApiHelper";
import { useForm } from "antd/es/form/Form";
import { LeftOutlined } from "@ant-design/icons";
import { fetchStart, fetchSuccess } from "../../appRedux/actions";
import moment from "moment";
import { getTargetedBusinessTypeForPromotion } from "../../util/helper";

const { TextArea } = Input;
const FormItem = Form.Item;

const AddEditPromotion = () => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const history = useHistory();

  const [hsCodeData, setHsCodeData] = useState(null);
  const [portsData, setPortsData] = useState(null);
  const [PromotionData, setPromotionData] = useState(null);
  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [selectedPortId, setSelectedPortId] = useState(null);
  const [displayedHsCode, setDisplayedHsCode] = useState(null);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");

  let isEditDisabled = false;
  let isEdit = isValidElement(id);

  useEffect(() => {
    getPromotionById(id);
    dispatch(getCategoryListAction());
    dispatch(getHSCodeAction());
  }, [dispatch, id]);

  const authUser = useSelector(({ auth }) => auth.userData);
  const categoryList = useSelector((state) => state.myProducts.categoryList);
  const hsCodeList = useSelector((state) => state.myProducts.hsCodeList);
  const countries = useSelector(({ common }) => common.countries);
  const ports = useSelector(({ common }) => common.ports);

  useEffect(() => {
    if (PromotionData?.hsCodeId) {
      if (!hsCodeList) {
        dispatch(getHSCodeAction());
        return;
      }
      const selectedHsCode = hsCodeList.find(
        (item) => item.id === PromotionData.hsCodeId
      );
      if (selectedHsCode) {
        const formattedHsCode = `${selectedHsCode.code} - ${selectedHsCode.description}`;
        setDisplayedHsCode(formattedHsCode);
        form.setFieldsValue({ hscode: PromotionData.hsCodeId });
      } else {
        setDisplayedHsCode(null);
      }
    } else {
      setDisplayedHsCode(null);
    }
  }, [PromotionData, hsCodeList, form, dispatch]);

  const promotionList = ["Platinum", "Diamond", "Silver"];

  const getPromotionById = async (promoid) => {
    if (isValidString(promoid)) {
      dispatch(fetchStart());
      try {
        const promotionById = await axiosInstance.get(
          `${BASE_API_CONFIG.baseURL}/promotions/${promoid}`
        );
        setPromotionData(promotionById?.data?.data);
        console.log(PromotionData);
      } catch (error) {
      } finally {
        dispatch(fetchSuccess());
      }
    }
  };

  useEffect(() => {
    if (PromotionData?.id) {
      const formattedStartDate = moment(PromotionData?.startDate).format(
        "DD-MM-YYYY"
      );
      const formattedEndDate = moment(PromotionData?.endDate).format(
        "DD-MM-YYYY"
      );
      //
      form.setFieldsValue({
        promotionTitle: PromotionData?.title,
        promotionCategory: PromotionData?.category,
        productCategory: PromotionData?.categoryId,
        hscode: displayedHsCode,
        promotionMessage: PromotionData?.description,
        promotionStart: moment(formattedStartDate, "DD-MM-YYYY"),
        promotionEnd: moment(formattedEndDate, "DD-MM-YYYY"),
        destinationCountry: PromotionData?.countryId,
        destinationPort: PromotionData?.portId,
      });
    }
  }, [PromotionData, form]);

  const [filteredHsCodeData, setFilteredHsCodeData] = useState(hsCodeList);

  const [dataId, setDataId] = useState();

  const onFinish = async (values) => {
    try {
      const data = {
        title: values?.promotionTitle,
        description: values?.promotionMessage,
        category: values?.promotionCategory,
        categoryId: values?.productCategory,
        hsCodeId: values?.hscode,
        startDate: values?.promotionStart,
        endDate: values?.promotionEnd,
        countryId: values?.destinationCountry,
        portId: values?.destinationPort,
        targetedBusinessType: [getTargetedBusinessTypeForPromotion(authUser)],
        userId: authUser?.id,
      };
      if (isEdit) {
        const response = await axiosInstance.put(
          `${BASE_API_CONFIG.baseURL}/promotions/${id}`,
          data
        );
        if (response.status === 200) {
          message.success("success", "Promotion saved successfully");
          history.goBack();
        } else {
          message.error("error", "Failed to save promotion");
        }
      } else {
        const response = await axiosInstance.post(
          `${BASE_API_CONFIG.baseURL}/promotions`,
          data
        );
        if (response.status === 200) {
          message.success("success", "Promotion saved successfully");
          history.goBack();
        } else {
          message.error("error", "Failed to save promotion");
        }
      }
    } catch (error) {
      message.error("error", "An error occurred while saving promotion");
      console.error("Error:", error);
    }
  };

  const { Option } = Select;

  const handleChangeProductCategory = (value) => {
    form.resetFields(["hsCode"]);
    setFilteredHsCodeData(null);
    setHsCodeData(hsCodeList?.filter((item) => item?.categories?.id === value));
  };
  const handleChangeDestinationCountry = (value) => {
    const selectedCountry = countries?.find((item) => item?.id === value);
    setSelectedCountryId(selectedCountry?.id);
    setPortsData(
      ports?.filter((item) => item?.countryId === selectedCountry?.id)
    );
  };
  const handleChangePort = (value) => {
    setSelectedPortId(value);
  };

  const handleHSCodeSearch = (value) => {
    const trimmedValue = value.trim();
    const containsNumbers = /^\d+$/.test(trimmedValue.toLowerCase());

    setFilteredHsCodeData(() => {
      const filterFunction = (option) => {
        const codeString = option.code
          .toString()
          .replace(/\s+/g, "")
          .toLowerCase();
        const descriptionString = option.description
          .replace(/[\n\r"]/g, "")
          .toLowerCase();

        return containsNumbers
          ? codeString.includes(trimmedValue.toLowerCase())
          : descriptionString.includes(trimmedValue.toLowerCase());
      };

      return isValidElement(hsCodeData)
        ? hsCodeData.filter(filterFunction)
        : hsCodeList?.filter(filterFunction);
    });
  };

  return (
    <Card
      className="gx-card myexim-detail-card"
      title={
        <div className="myexim-table-title gx-text-capitalize">
          <LeftOutlined
            className={"back-icon"}
            size="large"
            onClick={() => {
              history.goBack();
            }}
          />
          {isEdit ? "Edit" : "Add"} Promotion
        </div>
      }
    >
      <Form form={form} onFinish={onFinish} scrollToFirstError={true}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="Promotion Title"
              name="promotionTitle"
              rules={[
                {
                  required: true,
                  message: "Promotion Title is required",
                },
              ]}
            >
              <Input
                autoComplete={"off"}
                placeholder={"Enter Promotion Title "}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <FormItem
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="Product Category"
              name="productCategory"
              rules={[
                {
                  required: true,
                  message: "Product Category is required",
                },
              ]}
            >
              <Select
                showSearch
                size="large"
                name="productCategory"
                placeholder="Choose Product Category"
                onChange={handleChangeProductCategory}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {categoryList?.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="HS code / Item Description"
              name="hscode"
              rules={[
                {
                  required: true,
                  message: "HS code is required",
                },
              ]}
            >
              <Select
                showSearch
                size="large"
                name="hscode"
                placeholder="Choose HS code / Item Description"
                filterOption={false}
                disabled={isEditDisabled}
                onSearch={handleHSCodeSearch}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {isValidElement(filteredHsCodeData)
                  ? filteredHsCodeData?.map((option) => (
                      <Option key={option.id} value={option.id}>
                        {option.code} -{" "}
                        {option.description.replace(/[\n\r"]/g, "")}
                      </Option>
                    ))
                  : hsCodeData?.map((option) => (
                      <Option key={option.id} value={option.id}>
                        {option.code} - {option.description}
                      </Option>
                    ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <FormItem
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="Promotion Start"
              name="promotionStart"
              rules={[
                {
                  required: true,
                  message: "Promotion Start is required",
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                format="DD-MM-YYYY"
                getPopupContainer={(trigger) => trigger.parentNode}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="Promotion End"
              name="promotionEnd"
              rules={[
                {
                  required: true,
                  message: "Promotion End is required",
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                format="DD-MM-YYYY"
                getPopupContainer={(trigger)=>trigger.parentNode}
                disabledDate={(currentDate) => {
                  const promotionStartDate =
                    form.getFieldValue("promotionStart");
                  return (
                    currentDate && currentDate.isBefore(promotionStartDate)
                  );
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="Promotion Message"
              name="promotionMessage"
              rules={[
                {
                  required: true,
                  message: "Promotion Message is required",
                },
              ]}
            >
              <TextArea
                rows={10}
                placeholder={
                  "Provide More details about promotion message with specifications."
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item className="gx-text-center">
              <Button
                type="secondary"
                size="large"
                style={{ width: 150 }}
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{ width: 150 }}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default AddEditPromotion;
