import React, { useEffect, useState } from "react";
import { notification, Button, Card, Form, List, Modal, message } from "antd";
import { isValidElement, isValidString } from "../../../util/ApiHelper";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LeftOutlined } from "@ant-design/icons";
import { fetchStart, fetchSuccess } from "../../../appRedux/actions";
import { axiosInstance, BASE_API_CONFIG } from "../../../util/Api";
import TextArea from "antd/lib/input/TextArea";
import Messages from "../../messages";

const BannerView = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authUser = useSelector(({ auth }) => auth.userData);

  const queryString = window.location.search;
  const id = new URLSearchParams(queryString).get("id");

  const [bannerData, setbannerData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        setIsModalOpen(false);
        axiosInstance
          .post(`${BASE_API_CONFIG.baseURL}/messages`, {
            text: values.text,
            senderId: authUser?.id,
            receiverId: bannerData.userId,
            promotionId: bannerData.id,
          })
          .then((response) => {
            notification.success({
              message: "Success",
              description: "Your message has been submitted successfully.",
            });
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const navigateBusinessCard = (id) => {
    if (id) {
      history.push(`/user-profile?id=${id}`);
    }
  };

  const getBannerById = async (id) => {
    if (isValidString(id)) {
      dispatch(fetchStart());
      try {
        const bannerById = await axiosInstance.get(
          `${BASE_API_CONFIG.baseURL}/promotions/${id}`
        );
        setbannerData(bannerById?.data?.data);
      } catch (error) {
        console.error("Error fetching promotion details:", error);
      } finally {
        dispatch(fetchSuccess());
      }
    }
  };
  useEffect(() => {
    getBannerById(id);
  }, [id]);

  const data = [
    {
      label: "Promotion Title",
      value: bannerData?.title,
    },
    {
      label: "Company Name",
      value: (
        <div>
          {bannerData?.user?.companyName} &nbsp;&nbsp;{" "}
          <span
            onClick={() => navigateBusinessCard(bannerData?.userId)}
            className={"myexim-link"}
          >
            View Profile
          </span>
        </div>
      ),
    },
    {
      label: "Promotion Message",
      value: bannerData?.description,
    },
    {
      label: "Product Category",
      value: bannerData?.categories?.name?.replace(/\.$/,""),
    },
    {
      label: "HS Code / Item Description",
      value: bannerData?.hsCode
        ? `${bannerData.hsCode.code} - ${bannerData.hsCode.description}`
        : "N/A",
    },
    {
      label: "Destination Country",
      value: bannerData?.country?.country,
    },
    {
      label: "Destination Port",
      value: bannerData?.port?.port,
    },
  ];

  return (
    isValidElement(bannerData) && (
      <>
        <Card className="myexim-detail-card" style={{ width: "100%" }}>
          <div className="myexim-table-title">
            <LeftOutlined
              className={"back-icon"}
              size="large"
              onClick={() => {
                history.goBack();
              }}
            />
            Promotion Details
          </div>
          <div className="logo-with-list gx-mt-4">
            <div style={{ flex: 1 }}>
              <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item) =>
                  item?.value && (
                    <List.Item className="myexim-details-list">
                      <List.Item.Meta
                        title={<div>{item.label}</div>}
                        description={
                          isValidString(item.value) ? item.value : "-"
                        }
                      />
                    </List.Item>
                  )
                }
              />
              <List.Item className="myexim-details-list"></List.Item>
            </div>
          </div>
          <Button
            size="large"
            type="primary"
            onClick={showModal}
            style={{ float: "right" }}
          >
            Reply
          </Button>

          <Modal
            title="Send Message"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Form form={form} name="MessageForm">
              <Form.Item
                name="text"
                rules={[{ required: true, message: "Please enter text!" }]}
              >
                <TextArea rows={5} placeholder="Add your notes" />
              </Form.Item>
            </Form>
          </Modal>
          {/* <div
          style={{ justifyContent: "end", display: "flex", flex: 1 }}
          className="gx-pt-4"
        ></div> */}
        </Card>
        <Messages
          id={bannerData.id}
          loggedInUserId={authUser.id}
          isQuotation={false}
        />
      </>
    )
  );
};

export default BannerView;
