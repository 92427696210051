import { REQUEST_QUOTATIONS } from "../types/RequestQuotationAction";

export const getRequestQuotationList = (page = 1, limit = 10) => {
  return {
    type: REQUEST_QUOTATIONS.GET_REQUEST_QUOTATIONS_LIST,
    page,
    limit,
  };
};

export const deleteRFQDataAction = (RFQ_id) => {
  return {
    type: REQUEST_QUOTATIONS.DELETE_QUOTATIONS_FROM_LIST,
    RFQ_id,
  };
};

export const searchRFQDataAction = (searchText) => {
  return {
    type: REQUEST_QUOTATIONS.GET_SEARCH_QUOTATIONS_FROM_LIST,
    searchText,
  };
};

export const getMessagesAction = (isQuotation, id, loggedInUserId) => {
  return {
    type: REQUEST_QUOTATIONS.GET_MESSAGES,
    isQuotation,
    id,
    loggedInUserId,
  };
};
