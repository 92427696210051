export const MY_PRODUCTS_TYPES = {
  GET_PRODUCTS_LIST: 'get_products_list',
  GET_PRODUCTS_LIST_SUCCESS: 'get_products_list_success',
  CREATE_PRODUCT: 'create_product',
  CREATE_PRODUCT_SUCCESS: 'create_product_success',
  GET_CATEGORY_LIST: 'get_category_list',
  GET_CATEGORY_LIST_SUCCESS: 'get_category_list_success',
  GET_HS_CODE: 'get_hs_code',
  GET_HS_CODE_SUCCESS: 'get_hs_code_success',
  DELETE_MY_PRODUCT:'delete_my_product',
  DELETE_MY_PRODUCT_SUCCESS:'delete_my_product_success',
  GET_SEARCH_PRODUCTS:'get_search_products',
  SEARCH_PRODUCTS_SUCCESS:'search_products_success',
  GET_PRODUCT_BY_ID: 'get_product_by_id',
  GET_PRODUCT_BY_ID_SUCCESS: 'get_product_by_id_success',
  GET_PRODUCT_UNIT_LIST:'get_product_unit_list',
  PRODUCT_UNIT_LIST_SUCCESS:'product_unit_list_success'
};
