import React, { useEffect, useState } from "react";
import { Card, Image, List, Modal } from "antd";
import { isValidElement } from "util/ApiHelper";
import { useHistory } from "react-router-dom";
import EnquiryStatusTags from "./EnquiriesStatus";
import { Button } from "antd";
import { axiosInstance, BASE_API_CONFIG } from "../../util/Api";
import { RFQStatus } from "../../util/Constant";
import { ExclamationCircleOutlined, LeftOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { isArrayNonEmpty, isValidString } from "../../util/ApiHelper";
import { useDispatch } from "react-redux";
import { fetchStart, fetchSuccess } from "../../appRedux/actions";
import { getNewLine } from "util/helper";
import { capitalize } from "lodash";

const EnquiryDetails = ({}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");
  const moment = require("moment");

  const [fileName, setFileName] = useState("");
  const [showRejectModel, setShowRejectModel] = useState(false);
  const [enquriesData, setEnquriesData] = useState(null);
  const [reason, setReason] = useState("");
  const navigateUserProfile = (id) => {
    if (id) {
      history.push(`/users/details?id=${id}`);
    }
  };
  useEffect(() => {
    if (isArrayNonEmpty(enquriesData?.productImages)) {
      const fileNames = enquriesData?.productImages?.map((image) => {
        return image;
      });
      setFileName(fileNames);
    }
  }, [enquriesData]);

  const getEnquiryById = async (id) => {
    if (isValidString(id)) {
      dispatch(fetchStart());
      try {
        const enquriesById = await axiosInstance.get(
          `${BASE_API_CONFIG.baseURL}/admin/enquiries/${id}`
        );
        setEnquriesData(enquriesById?.data?.data);
      } catch (error) {
      } finally {
        dispatch(fetchSuccess());
      }
    }
  };

  useEffect(() => {
    getEnquiryById(id);
  }, [id]);

  const data = [
    {
      label: "Posted date",
      value: moment(enquriesData?.createdAt).format("DD-MMM-YYYY"),
    },
    {
      label: "Company Name",
      value: (
        <div>
          {enquriesData?.user?.companyName} &nbsp;&nbsp;{" "}
          <span
            onClick={() => navigateUserProfile(enquriesData?.user?.id)}
            className={"myexim-link"}
          >
            View Profile
          </span>
        </div>
      ),
    },
    {
      label: "Party Name",
      value: capitalize(enquriesData?.targetedBusinessType),
    },
    {
      label: "Product Category",
      value: enquriesData?.categories?.name,
    },
    {
      label: "HS Code / Item Description",
      value: `${enquriesData?.hscode?.code} - ${enquriesData?.hscode?.description}`,
    },
    {
      label: "Product Name",
      value: enquriesData?.productName,
    },
    {
      label: "Quantity",
      value: enquriesData?.qty
        ? `${enquriesData?.qty} ${enquriesData?.units || ""}`
        : enquriesData?.noOfContainers
        ? `${enquriesData.noOfContainers} ${
            enquriesData?.noOfContainers == 1 ? "Container" : "Containers"
          }`
        : "",
    },
    {
      label: "Description",
      value: getNewLine(enquriesData?.description),
    },
    {
      label: "Source Country",
      value: enquriesData?.sourceCountry,
    },
    {
      label: "Destination Country",
      value: enquriesData?.destinationCountry,
    },
    {
      label: "Destination Port",
      value: enquriesData?.destinationPort,
    },
    {
      label: "Status",
      value: <EnquiryStatusTags status={enquriesData?.status} />,
    },
  ];

  console.log("enquriesData", enquriesData);

  const onApprovedClicked = async () => {
    history.goBack();
    const data = { id: id, status: RFQStatus.PUBLISHED };
    await axiosInstance.post(
      `${BASE_API_CONFIG.baseURL}/admin/enquiries`,
      data
    );
  };

  const onSubmitPressed = async () => {
    if (isValidString(reason)) {
      history.goBack();
      const data = {
        id: id,
        status: RFQStatus.REJECTED,
        reason: reason,
      };
      await axiosInstance.post(
        `${BASE_API_CONFIG.baseURL}/admin/enquiries`,
        data
      );
    } else {
      alert("Please enter valid reason");
    }
  };

  const handleCancelModel = () => {
    setShowRejectModel(false);
    setReason("");
  };

  const onChangeClicked = (value) => {
    setReason(value);
  };

  return (
    isValidElement(enquriesData) && (
      <Card className="myexim-detail-card">
        <div className="myexim-table-title">
          <LeftOutlined
            className={"back-icon"}
            size="large"
            onClick={() => {
              history.goBack();
            }}
          />
          Enquiry Details
        </div>
        <List
          className="gx-mt-4"
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) =>
            item?.value && (
              <List.Item className="myexim-details-list">
                <List.Item.Meta
                  title={<div>{item.label}</div>}
                  description={item.value}
                />
              </List.Item>
            )
          }
        />
        {fileName?.length > 0 && (
          <div className="attachments">
            <div className="sub-header"> Attachments</div>
            <div className="gx-pt-4">
              {fileName?.map((fileName, index) => (
                <div key={index} className="gx-pb-3">
                  <Image height={150} width={150} src={fileName?.src} />
                </div>
              ))}
            </div>
          </div>
        )}
        <div
          style={{ justifyContent: "center", display: "flex", flex: 1 }}
          className="gx-pt-4"
        >
          <Button
            type="secondary"
            size="large"
            disabled={enquriesData?.status === RFQStatus.REJECTED}
            onClick={() => {
              setShowRejectModel(true);
            }}
          >
            REJECT
          </Button>
          <Button
            type="primary"
            size="large"
            onClick={() => {
              onApprovedClicked();
            }}
            disabled={enquriesData?.status === RFQStatus.PUBLISHED}
          >
            APPROVE
          </Button>
        </div>
        <Modal
          title="Confirmation"
          visible={showRejectModel}
          onCancel={handleCancelModel}
          centered // Center the modal vertically
          footer={null} // Hide the default footer
        >
          <div className={"delete-modal-content"}>
            <ExclamationCircleOutlined className={"delete-icon"} size={40} />{" "}
            <p>Do you want to reject this item?</p>
            <TextArea
              placeholder="Enter Reason"
              size={"large"}
              value={reason}
              onChange={(e) => onChangeClicked(e.target.value)} // Call your function on change
            />
            <div style={{ marginTop: 30 }}>
              <Button type="default" onClick={() => handleCancelModel()}>
                Cancel
              </Button>
              <Button type="primary" onClick={() => onSubmitPressed()}>
                Submit
              </Button>
            </div>
          </div>
        </Modal>
      </Card>
    )
  );
};

export default EnquiryDetails;
