import React, { useEffect, useState } from "react";
import { Card, Table, Space } from "antd";
import { Dropdown } from "antd";
import { MoreOutlined, EyeOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { searchProductsAction } from "appRedux/actions/myProductsAction";
import { useHistory } from "react-router-dom";
import { isArrayNonEmpty, isValidElement, isValidString } from "util/ApiHelper";
import { axiosInstance, BASE_API_CONFIG } from "../../util/Api";
import { fetchSuccess, fetchStart } from "../../appRedux/actions";
import EnquiryStatusTags from "../enquiries/EnquiriesStatus";
import { EnquiryStatus } from "../../util/Constant";
import FilterButton from "../../components/FilterButton/FilterButton";

let navigateToDetails;

const MyProductsList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [productAdminData, setProductAdminData] = useState(null);

  const currentPage = 1;
  const dataSize = 10;

  const columns = [
    {
      title: "Product Name",
      key: "Product Name",
      dataIndex: "name",
      sorter: true,
      width: "25%",
      render: (text, record) => (
        <div className={"gx-flex-row gx-align-items-center"}>
          <div className={"gx-pr-2"}>
            <img
              alt="..."
              className={"gx-size-40 gx-rounded-base gx-border-2"}
              src={
                isValidString(record?.primaryImage)
                  ? record?.primaryImage
                  : "https://via.placeholder.com/150"
              }
            />
          </div>
          <div className={"highlighted-column"}>{text}</div>
        </div>
      ),
    },
    {
      title: "HS Code",
      key: "hsCode",
      render: (record) => <div>{record?.hscode?.code}</div>,
    },
    {
      title: "Category Name",
      key: "Product Name",
      sorter: true,
      width: "25%",
      render: (record) => <div>{record?.categories?.name}</div>,
    },
    {
      title: "Status",
      key: "Status",
      sorter: true,
      render: (record) => {
        return <EnquiryStatusTags status={record?.status} />;
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (record) => (
        <div
          className="myexim-action-icon"
          onClick={(e) => e.stopPropagation()}
        >
          <Dropdown
            menu={{
              items: [
                {
                  label: "View Details",
                  key: "1",
                  icon: <EyeOutlined />,
                  onClick: () => {
                    navigateToDetails(record?.id);
                  },
                },
              ],
            }}
            trigger={["click"]}
          >
            <Space>
              <MoreOutlined />
            </Space>
          </Dropdown>
        </div>
      ),
    },
  ];

  const getProductListAPICCall = async (page, limit) => {
    const response = await axiosInstance.get(
      `${BASE_API_CONFIG.baseURL}/admin/products?page=${page}&limit=${limit}`
    );
    setProductAdminData(response?.data?.data);
    dispatch(fetchSuccess());
  };

  useEffect(() => {
    dispatch(fetchStart());
    getProductListAPICCall(currentPage, dataSize);
  }, [dispatch]);
  const [searchText, setSearchText] = useState(null);

  const searchProductList = useSelector(
    (state) => state.myProducts.searchProducts
  );

  const onShowSizeChange = (current, pageSize) => {
    getProductListAPICCall(current, pageSize);
  };

  const onSearch = (e) => {
    if (isValidString(e?.target?.value)) {
      dispatch(searchProductsAction(e.target.value));
      setSearchText(e.target.value);
    }
  };
  navigateToDetails = (id) => {
    if (isValidElement(id)) {
      dispatch(fetchStart());
      history.push(`products/details?id=${id}`);
    }
  };

  const tableData =
    isValidString(searchText) && searchText.length > 0
      ? isArrayNonEmpty(searchProductList?.rows)
        ? searchProductList
        : []
      : productAdminData;

  const [selectedStatus, setSelectedStatus] = useState(null);

  const handleSelectStatus = (value) => {
    setSelectedStatus(value);
  };
  const filteredTableData =
    tableData &&
    tableData.rows &&
    selectedStatus &&
    selectedStatus !== EnquiryStatus.PUBLISHED
      ? tableData.rows.filter((record) => record.status === selectedStatus)
      : tableData && tableData.rows
      ? tableData.rows
      : [];

  return (
    <Card
      title={
        <div style={{ display: "flex" }}>
          <div className="myexim-table-title">My Products</div>
        </div>
      }
    >
      <FilterButton
        className={"rfq-filter-button"}
        availableStatus={Object.values(EnquiryStatus)}
        onSelectStatus={handleSelectStatus}
      />
      <div>
        <Table
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          onRow={(record) => {
            return {
              onClick: (e) => {
                if (
                  !e.target.classList.contains(
                    "ant-dropdown-menu-title-content"
                  )
                ) {
                  navigateToDetails(record?.id);
                }
              },
            };
          }}
          className="gx-table-responsive my-product-table myexim-table"
          total={tableData?.count}
          columns={columns}
          dataSource={tableData?.rows}
          pagination={{
            position: ["bottomRight"],
            showSizeChanger: true,
            pageSize: 10,
            total: tableData?.count,
            onChange: onShowSizeChange,
          }}
        />
        <div style={{ padding: "10px 0", fontWeight: "bold" }}>
          Your Products with "Pending Approval" status require review by our
          team and will be approved or rejected within 24 hours.
        </div>
      </div>
    </Card>
  );
};

export default MyProductsList;
